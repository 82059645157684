// ** Custom Menu Components
import VerticalNavLink from './VerticalNavLink'
import VerticalNavGroup from './VerticalNavGroup'
import VerticalNavSectionTitle from './VerticalNavSectionTitle'
import AndroidVerticalNavSectionTitle from './AndroidVerticalNavSectionTitle'
import AndroidLocalVerticalNavSectionTitle from './AndroidLocalVerticalNavSectionTitle'

const resolveNavItemComponent = item => {
  if (item.sectionTitle) {
    if (localStorage.getItem('currentMode') === 'Android-Cloud') {
      return AndroidVerticalNavSectionTitle
    } else if (localStorage.getItem('currentMode') === 'Android-Local') {
      return AndroidLocalVerticalNavSectionTitle
    } else {
      return VerticalNavSectionTitle
    }
  }

  /* return localStorage.getItem('currentMode') == 'Android-Cloud'
    ? AndroidVerticalNavSectionTitle
    : VerticalNavSectionTitle */

  if (item.children) return VerticalNavGroup

  return VerticalNavLink
}

const VerticalNavItems = props => {
  // ** Props
  const { verticalNavItems } = props

  // console.log('xxx', verticalNavItems)
  let RenderMenuItems
  if (localStorage.getItem('currentMode') === 'Android-Local' && verticalNavItems.length == 7) {
    const _arr = verticalNavItems.slice(0, verticalNavItems.length - 2)
    const _arr2 = verticalNavItems.slice(verticalNavItems.length - 2, verticalNavItems.length)
    console.log(_arr, _arr2)

    return (
      <>
        <div className='w-full !mt-[-6px]'>

          {_arr?.map((item, index) => {
            const TagName = resolveNavItemComponent(item)

            return <TagName {...props} key={index} item={item} />
          })}
        </div>
        <div className='w-full flex-1 flex flex-col justify-end'>
          {_arr2?.map((item, index) => {
            const TagName = resolveNavItemComponent(item)

            return <TagName {...props} key={index} item={item} />
          })}
        </div>
      </>
    )
  } else {
    RenderMenuItems =
      verticalNavItems.length !== 0
        ? verticalNavItems?.map((item, index) => {
          const TagName = resolveNavItemComponent(item)

          return <TagName {...props} key={index} item={item} />
        })
        : AndroidLocalVerticalNavSectionTitle()
  }

  return <>{RenderMenuItems}</>
}

export default VerticalNavItems
