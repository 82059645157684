// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import dayjs from 'dayjs'

// ** Axios Imports
import axios from 'axios'
import { getWhatsappChatSummary, getWhatsappChatDetail, getAllDashboard } from '/src/@api/dashboard'

// ** Fetch User Profile

let leftPage = 0

// 首次的查询详情信息
let firstData = []

let rightTotalCount = 0

// SUMMARY
export const fetchUserProfile = createAsyncThunk('WeChat/fetchUserProfile', async () => {
  leftPage += 1

  let response = await getAllDashboard({
    username:
      JSON.parse(localStorage.getItem('DeviceUser')) != null
        ? JSON.parse(localStorage.getItem('DeviceUser')).username
        : '',
    params: {
      category: 'WECHAT_CHAT_SUMMARY',
      offset: (leftPage - 1) * 50,
      limit: 50,
      deviceUdid:
        JSON.parse(localStorage.getItem('currentDevice')) != null
          ? JSON.parse(localStorage.getItem('currentDevice'))['Unique Identifier']
          : ''
    }
  })

  if (response.data.contents.length > 0) {
    response.data.contents = response.data.contents.sort((x, y) => y.totalCount - x.totalCount)
  }

  // 判断是不是群信息 获取成员信息
  let chatroom = response.data.contents.length > 0 ? response.data.contents[0].wxid : ''
  let indexof = chatroom.indexOf('@')
  chatroom = chatroom.substr(indexof + 1, chatroom.length)

  let members = []
  if (chatroom == 'chatroom') {
    let getMembers = await getAllDashboard({
      username:
        JSON.parse(localStorage.getItem('DeviceUser')) != null
          ? JSON.parse(localStorage.getItem('DeviceUser')).username
          : '',
      params: {
        category: 'WECHAT_GROUP_MEMBERS',
        offset: 0,
        limit: 2000,
        deviceUdid:
          JSON.parse(localStorage.getItem('currentDevice')) != null
            ? JSON.parse(localStorage.getItem('currentDevice'))['Unique Identifier']
            : '',
        userID: response.data.contents.length > 0 ? response.data.contents[0].wxid : '',
        rid: response.data.contents.length > 0 ? response.data.contents[0].sessions[0].chatid : ''
      }
    })
    if (getMembers.data.contents != null) {
      members = getMembers.data.contents
    } else {
      members = []
    }
  }

  if (firstData.length <= 0) {
    let defaultSelectData = await getAllDashboard({
      username:
        JSON.parse(localStorage.getItem('DeviceUser')) != null
          ? JSON.parse(localStorage.getItem('DeviceUser')).username
          : '',
      params: {
        category: 'WECHAT_CHAT_DETAIL',
        offset: 0,
        limit: 50,
        deviceUdid:
          JSON.parse(localStorage.getItem('currentDevice')) != null
            ? JSON.parse(localStorage.getItem('currentDevice'))['Unique Identifier']
            : '',
        userID: response.data.contents.length > 0 ? response.data.contents[0].wxid : '',
        rid: response.data.contents.length > 0 ? response.data.contents[0].sessions[0].chatid : ''
      }
    })

    if (defaultSelectData.data.contents !== null) {
      firstData = defaultSelectData.data.contents.map(row => {
        if (row.messageType === 34) {
          return { ...row, attachment: { url: row.attachment.url + '.mp3' } }
        } else { return { ...row } }
      }).reverse()

      rightTotalCount = defaultSelectData.data.totalCount
    }
  }

  const userId = response.data.contents[0].wxid
  response.data.contents[0].sessions = response.data.contents[0].sessions.map(item => ({ ...item, userWxId: userId }))

  localStorage.setItem('weChatItem', JSON.stringify(response.data.contents[0].sessions[0]))

  let userWxIdList =
    response.data.contents == null
      ? []
      : response.data.contents.map(row => {
        return {
          value: row.wxid,
          label: row.wxid
        }
      })

  return {
    data: response.data.contents[0].sessions,
    defaultSelectData: firstData,
    leftPage: leftPage,
    leftTotalCount: response.data.contents[0].totalCount,
    rightTotalCount,
    userWxId: response.data.contents[0].wxid,
    userWxIdList: userWxIdList,
    members
  }
})

// 切换用户
export const switchUser = createAsyncThunk('WeChat/switchUser', async (obj, { dispatch }) => {
  let leftPage = 0

  const response = await getAllDashboard({
    username:
      JSON.parse(localStorage.getItem('DeviceUser')) != null
        ? JSON.parse(localStorage.getItem('DeviceUser')).username
        : '',
    params: {
      category: 'WECHAT_CHAT_SUMMARY',
      offset: (leftPage - 1) * 50,
      limit: 50,
      deviceUdid:
        JSON.parse(localStorage.getItem('currentDevice')) != null
          ? JSON.parse(localStorage.getItem('currentDevice'))['Unique Identifier']
          : ''
    }
  })

  if (response.data.contents.length > 0) {
    response.data.contents = response.data.contents.sort((x, y) => y.totalCount - x.totalCount)
  }

  let defaultSelectData = await getAllDashboard({
    username:
      JSON.parse(localStorage.getItem('DeviceUser')) != null
        ? JSON.parse(localStorage.getItem('DeviceUser')).username
        : '',
    params: {
      category: 'WECHAT_CHAT_DETAIL',
      offset: 0,
      limit: 50,
      deviceUdid:
        JSON.parse(localStorage.getItem('currentDevice')) != null
          ? JSON.parse(localStorage.getItem('currentDevice'))['Unique Identifier']
          : '',
      userID: obj,
      rid: response.data.contents.length > 0 ? response.data.contents[0].sessions[0].chatid : ''
    }
  })

  let selected = response.data.contents.find(row => row.wxid == obj)

  return {
    leftTotalCount: selected.totalCount,
    wxid: selected.wxid,
    data: selected.sessions == null ? [] : selected.sessions,
    defaultSelectData: defaultSelectData.data.contents == null ? [] : defaultSelectData.data.contents.reverse()
  }
})

// ** Select Message
let PK = ''
let rightPage = 1

export const selectChat = createAsyncThunk('WeChat/selectChat', async (obj, { dispatch }) => {
  let weChatItem = JSON.parse(localStorage.getItem('weChatItem'))

  if (typeof obj == 'undefined') {
    obj = weChatItem
    rightPage = rightPage + 1
  } else {
    rightPage = 1
  }
  PK = obj.chatid

  // 判断是不是群信息 获取成员信息
  let chatroom = obj.wxid
  let indexof = chatroom.indexOf('@')
  chatroom = chatroom.substr(indexof + 1, chatroom.length)

  let members = []
  if (chatroom == 'chatroom') {
    let getMembers = await getAllDashboard({
      username:
        JSON.parse(localStorage.getItem('DeviceUser')) != null
          ? JSON.parse(localStorage.getItem('DeviceUser')).username
          : '',
      params: {
        category: 'WECHAT_GROUP_MEMBERS',
        offset: 0,
        limit: 2000,
        deviceUdid:
          JSON.parse(localStorage.getItem('currentDevice')) != null
            ? JSON.parse(localStorage.getItem('currentDevice'))['Unique Identifier']
            : '',
        userID: obj.userWxId,
        rid: PK
      }
    })
    if (getMembers.data.contents != null) {
      members = getMembers.data.contents
    } else {
      members = []
    }
  }

  let response = await getAllDashboard({
    username:
      JSON.parse(localStorage.getItem('DeviceUser')) != null
        ? JSON.parse(localStorage.getItem('DeviceUser')).username
        : '',
    params: {
      category: 'WECHAT_CHAT_DETAIL',
      offset: (rightPage - 1) * 50,
      limit: 50,
      deviceUdid:
        JSON.parse(localStorage.getItem('currentDevice')) != null
          ? JSON.parse(localStorage.getItem('currentDevice'))['Unique Identifier']
          : '',
      userID: obj.userWxId,
      rid: PK
    }
  })

  return {
    contact: obj,
    selected:
      response.data.contents == null
        ? []
        : response.data.contents
          .map(row => {
            if (row.messageType === 34) {
              return {
                ...row,
                attachment: {
                  url: row.attachment.url + '.mp3'
                }
              }
            } else {
              return {
                ...row
              }
            }
          })
          .reverse(),
    rightTotalCount: response.data.totalCount,
    members: members
  }
})

export const setPerViewImage = createAsyncThunk('WeChat/setPerView', async (obj, { dispatch }) => {
  return obj
})

export const loadingStatus = createAsyncThunk('WeChat/loadingStatus', async (obj, { dispatch }) => {
  return true
})

export const appChatSlice = createSlice({
  name: 'WeChat',
  initialState: {
    userWxId: '',
    userWxIdList: '',
    leftTotalCount: 0,
    rightTotalCount: 0,
    members: [],
    selectedMessage: {
      contact: {},
      selected: []
    },
    list: [],
    preViewImage: {
      open: false,
      url: ''
    },
    flag: false
  },
  reducers: {
    removeSelectedChat: state => {
      state.selectedChat = null
    }
  },
  extraReducers: builder => {
    builder.addCase(switchUser.fulfilled, (state, action) => {
      state.userWxId = action.payload.wxid

      state.list = action.payload.data
      state.selectedMessage = {
        contact: action.payload.data.length > 0 ? action.payload.data[0] : {},
        selected: action.payload.defaultSelectData
      }

      state.leftTotalCount = action.payload.leftTotalCount
    })

    builder.addCase(fetchUserProfile.fulfilled, (state, action) => {
      state.userWxId = action.payload.userWxId
      state.userWxIdList = action.payload.userWxIdList
      state.list = [...state.list, ...action.payload.data]
      state.rightTotalCount = action.payload.rightTotalCount

      state.leftTotalCount = action.payload.leftTotalCount
      if (action.payload.leftPage == 1) {
        state.selectedMessage = { contact: action.payload.data[0], selected: action.payload.defaultSelectData }
      }
      state.members = action.payload.members
    })
    builder.addCase(selectChat.fulfilled, (state, action) => {
      state.members = action.payload.members
      if (state.selectedMessage.contact.chatid == action.payload.contact.chatid) {
        state.selectedMessage.selected = [...state.selectedMessage.selected, ...action.payload.selected]
      } else {
        state.selectedMessage.selected = action.payload.selected
      }

      state.selectedMessage.contact = action.payload.contact

      state.rightTotalCount = action.payload.rightTotalCount

      state.flag = false
    })

    builder.addCase(setPerViewImage.fulfilled, (state, action) => {
      state.preViewImage = action.payload
    })

    builder.addCase(loadingStatus.fulfilled, (state, action) => {
      state.flag = true
    })
  }
})

export const { removeSelectedChat } = appChatSlice.actions

export default appChatSlice.reducer
