// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import dayjs from 'dayjs'

// ** Axios Imports
import axios from 'axios'
import { getWhatsappChatDetail, getAllDashboard } from '/src/@api/dashboard'

// ** Fetch User Profile

let leftPage = 0

// 首次的查询详情信息
let firstData = []

// 首次群组
let members = []

// SUMMARY
export const fetchUserProfile = createAsyncThunk('Messenger/fetchUserProfile', async () => {
  // userinfo
  let username =
    JSON.parse(localStorage.getItem('DeviceUser')) != null
      ? JSON.parse(localStorage.getItem('DeviceUser')).username
      : ''

  let deviceUdid =
    JSON.parse(localStorage.getItem('currentDevice')) != null
      ? JSON.parse(localStorage.getItem('currentDevice'))['Unique Identifier']
      : ''

  try {
    leftPage += 1

    const response = await getAllDashboard({
      username: username,
      params: {
        category: 'FACEBOOK_MESSENGER_SUMMARY',
        offset: (leftPage - 1) * 50,
        limit: 50,
        deviceUdid: deviceUdid
      }
    })

    let userId = response.data.contents.length > 0 ? response.data.contents[0].uid : ''

    let rid =
      response.data.contents.length && response.data.contents[0].sessions != null
        ? response.data.contents[0].sessions[0].chatid
        : ''

    if (firstData.length <= 0) {
      // 如果是群组需要获取成员
      if (
        response.data.contents != null &&
        response.data.contents[0].sessions !== null &&
        response.data.contents[0].sessions[0].isGroupChat === 1
      ) {
        let getMembers = await getAllDashboard({
          username: username,
          params: {
            category: 'FACEBOOK_MESSENGER_GROUP_MEMBERS',
            offset: 0,
            limit: 2000,
            deviceUdid: deviceUdid,
            userID: userId,
            rid: rid
          }
        })

        members = getMembers.data.contents ?? []
      }

      let defaultSelectData = await getAllDashboard({
        username: username,

        params: {
          category: 'FACEBOOK_MESSENGER_DETAIL',
          offset: 0,
          limit: 50,
          deviceUdid: deviceUdid,
          userID: userId,
          rid: rid
        }
      })

      if (defaultSelectData.data.contents !== null) firstData = [...defaultSelectData.data.contents.reverse()]
    }

    if (response.data.contents.length && response.data.contents[0].sessions != null) {
      response.data.contents[0].sessions[0].userMessengerId = userId
      localStorage.setItem('MessengerItem', JSON.stringify(response.data.contents[0].sessions[0]))
    }

    let userMessengerIdList =
      response.data.contents == null
        ? []
        : response.data.contents.map(row => {
          return {
            value: row.uid,
            label: (
              <div>
                <span
                  style={{
                    display: 'inline-block',
                    width: '26px',
                    height: '26px',
                    background: '#ccc',
                    borderRadius: '50%',
                    lineHeight: '26px',
                    textAlign: 'center',
                    fontWeight: '700',
                    marginRight: '10px'
                  }}
                >
                  {(row.name + row.uid).substring(0, 1)}
                </span>
                {row.name}
              </div>
            )
          }
        })

    localStorage.setItem('userMessengerIdList', JSON.stringify(userMessengerIdList))

    return {
      data:
        response.data.contents != null && response.data.contents[0].sessions != null
          ? response.data.contents[0].sessions
          : [],
      defaultSelectData: firstData,
      leftPage: leftPage,
      leftTotalCount: response.data.contents != null ? response.data.contents[0].totalCount : 0,
      userMessengerId: response.data.contents != null ? response.data.contents[0].uid : '',
      userMessengerIdList: userMessengerIdList,
      members
    }
  } catch (error) {
    console.log(error)
  }
})

// 切换用户
export const switchUser = createAsyncThunk('Messenger/switchUser', async (obj, { dispatch }) => {
  // userinfo
  let username =
    JSON.parse(localStorage.getItem('DeviceUser')) != null
      ? JSON.parse(localStorage.getItem('DeviceUser')).username
      : ''

  let deviceUdid =
    JSON.parse(localStorage.getItem('currentDevice')) != null
      ? JSON.parse(localStorage.getItem('currentDevice'))['Unique Identifier']
      : ''

  let leftPage = 0

  let currentIndex = JSON.parse(localStorage.getItem('userMessengerIdList')).findIndex(row => row.value == obj)

  const response = await getAllDashboard({
    username: username,
    params: {
      category: 'FACEBOOK_MESSENGER_SUMMARY',
      offset: (leftPage - 1) * 50,
      limit: 50,
      deviceUdid: deviceUdid
    }
  })

  let defaultSelectData = await getAllDashboard({
    username: username,
    params: {
      category: 'FACEBOOK_MESSENGER_DETAIL',
      offset: 0,
      limit: 50,
      deviceUdid: deviceUdid,
      userID: obj,
      rid: response.data.contents.length > 0 ? response.data.contents[currentIndex].sessions[0].chatid : ''
    }
  })
  console.log(defaultSelectData, '333333')
  let selected = response.data.contents.find(row => row.uid == obj)

  return {
    leftTotalCount: selected.totalCount,
    uid: selected.uid,
    data: selected.sessions == null ? [] : selected.sessions,
    defaultSelectData: defaultSelectData.data.contents == null ? [] : defaultSelectData.data.contents.reverse()
  }
})

// ** Select Message
let PK = ''
let rightPage = 1

export const selectChat = createAsyncThunk('Messenger/selectChat', async (obj, { dispatch }) => {
  // userinfo
  let username =
    JSON.parse(localStorage.getItem('DeviceUser')) != null
      ? JSON.parse(localStorage.getItem('DeviceUser')).username
      : ''

  let deviceUdid =
    JSON.parse(localStorage.getItem('currentDevice')) != null
      ? JSON.parse(localStorage.getItem('currentDevice'))['Unique Identifier']
      : ''

  let MessengerItem = JSON.parse(localStorage.getItem('MessengerItem'))

  if (typeof obj == 'undefined') {
    obj = MessengerItem
    rightPage = rightPage + 1
  } else {
    rightPage = 1
  }
  PK = obj.chatid

  // 如果是群组需要获取成员

  let members = []
  if (obj.isGroupChat === 1) {
    let getMembers = await getAllDashboard({
      username: username,
      params: {
        category: 'FACEBOOK_MESSENGER_GROUP_MEMBERS',
        offset: 0,
        limit: 2000,
        deviceUdid: deviceUdid,
        userID: obj.userMessengerId,
        rid: PK
      }
    })

    members = getMembers.data.contents ?? []
  }

  let response = await getAllDashboard({
    username: username,
    params: {
      category: 'FACEBOOK_MESSENGER_DETAIL',
      offset: (rightPage - 1) * 50,
      limit: 50,
      deviceUdid: deviceUdid,
      userID: obj.userMessengerId,
      rid: PK
    }
  })

  return {
    contact: obj,
    selected: response.data.contents == null ? [] : response.data.contents.reverse(),
    rightTotalCount: response.data.totalCount,
    members
  }
})

export const setPerViewImage = createAsyncThunk('Messenger/setPerView', async (obj, { dispatch }) => {
  return obj
})

export const loadingStatus = createAsyncThunk('Messenger/loadingStatus', async (obj, { dispatch }) => {
  return true
})

export const appChatSlice = createSlice({
  name: 'Messenger',
  initialState: {
    userMessengerId: '',
    userMessengerIdList: '',
    leftTotalCount: 0,
    rightTotalCount: 0,
    members: [],
    selectedMessage: {
      contact: {},
      selected: []
    },
    list: [],
    preViewImage: {
      open: false,
      url: ''
    },
    flag: false
  },
  reducers: {
    removeSelectedChat: state => {
      state.selectedChat = null
    }
  },
  extraReducers: builder => {
    builder.addCase(switchUser.fulfilled, (state, action) => {
      state.userMessengerId = action.payload.uid

      state.list = action.payload.data
      state.selectedMessage = {
        contact: action.payload.data.length > 0 ? action.payload.data[0] : {},
        selected: action.payload.defaultSelectData
      }

      state.leftTotalCount = action.payload.leftTotalCount
    })

    builder.addCase(fetchUserProfile.fulfilled, (state, action) => {
      if (typeof action.payload == 'undefined') return
      state.userMessengerId = action.payload.userMessengerId

      state.userMessengerIdList = action.payload.userMessengerIdList
      state.list = [...state.list, ...action.payload.data]

      state.leftTotalCount = action.payload.leftTotalCount

      if (action.payload.leftPage == 1) {
        state.selectedMessage = {
          contact: action.payload.data > 0 ? action.payload.data : {},
          selected: action.payload.defaultSelectData
        }
      }
      state.members = action.payload.members
    })
    builder.addCase(selectChat.fulfilled, (state, action) => {
      state.members = action.payload.members

      if (state.selectedMessage.contact.chatid === undefined || state.selectedMessage.contact.chatid == action.payload.contact.chatid) {
        state.selectedMessage.selected = [...state.selectedMessage.selected, ...action.payload.selected]
      } else {
        state.selectedMessage.selected = action.payload.selected
      }

      state.selectedMessage.contact = action.payload.contact

      state.rightTotalCount = action.payload.rightTotalCount

      state.flag = false
    })

    builder.addCase(setPerViewImage.fulfilled, (state, action) => {
      state.preViewImage = action.payload
    })

    builder.addCase(loadingStatus.fulfilled, (state, action) => {
      state.flag = true
    })
  }
})

export const { removeSelectedChat } = appChatSlice.actions

export default appChatSlice.reducer
