// ** Toolkit imports
import { configureStore } from '@reduxjs/toolkit'

// ** Reducers
import email from 'src/store/apps/email'
import permissions from 'src/store/apps/permissions'
import TextMessages from 'src/store/apps/text-messages'
import WhatsApp from 'src/store/apps/whatsapp'
import Kik from 'src/store/apps/kik'
import Telegram from 'src/store/apps/telegram'
import Viber from 'src/store/apps/viber'
import Tinder from 'src/store/apps/tinder'
import Line from 'src/store/apps/line'
import Facebook from 'src/store/apps/facebook'
import Skype from 'src/store/apps/skype'
import Snapchat from 'src/store/apps/snapchat'
import WeChat from 'src/store/apps/we-chat'
import Messenger from 'src/store/apps/messenger'
import WaBusiness from 'src/store/apps/wa-business'

// android
import androidTextMessage from 'src/store/apps/android-text-messages'
import androidEmail from 'src/store/apps/android-email'
import androidWhatsapp from 'src/store/apps/android-whatsapp'

// android-local
import androidLocalTextMessages from 'src/store/apps/android-local-text-messages'

// common
import Common from 'src/store/apps/Common'

// photo
import Photo from 'src/store/apps/photo'

export const store = configureStore({
  reducer: {
    androidTextMessage,
    androidEmail,
    email,
    permissions,
    TextMessages,
    WhatsApp,
    Kik,
    Telegram,
    Line,
    Skype,
    Snapchat,
    Common,
    WeChat,
    Messenger,
    WaBusiness,
    androidWhatsapp,
    androidLocalTextMessages,
    Photo
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false
    })
})
