import React, { useState, useEffect } from 'react'
import { useRouter } from 'next/router'
import { useTranslation } from 'react-i18next'

const TipsBackup = () => {
  const router = useRouter()
  const { t } = useTranslation('common')

  let ios = ''
  let android = ''
  if (router.locale && router.locale != 'en') {
    ios = '/' + router.locale + '/guide/back-up-ios' + '-' + (router.locale == 'it-ch' ? 'it' : router.locale)
    android = '/' + router.locale + '/guide/back-up-android' + '-' + (router.locale == 'it-ch' ? 'it' : router.locale)
  } else {
    ios = '/guide/back-up-ios'
    android = '/guide/back-up-android'
  }

  let type = {
    'iOS-Cloud': ios,
    'Android-Cloud': android
  }

  if (localStorage.getItem('currentMode') == 'Android-Local') return

  let userSummary = JSON.parse(localStorage.getItem('userSummary'))

  // 跳转路径
  let href = type[localStorage.getItem('currentMode')] ?? '/'

  let showTips = false

  try {
    if (userSummary instanceof Array && userSummary.length) {
      let find = userSummary.find(row => row.href == router.asPath)

      if (find && find.num == 0) {
        showTips = true
      } else {
        showTips = false
      }
    }
  } catch (error) {
    console.log(error)
  }

  return (
    <>
      {showTips ? (
        <div
          style={{
            background: '#EFD238',
            margin: '0 auto',
            fontSize: '14px',
            borderRadius: '10px',
            color: '#41403E',
            padding: '10px',
            maxHeight: '84px',
            height: 'auto',
            marginBottom: '10px'
          }}
        >
          {t('No data on the target phone or the auto-backup of this app didnt turned on Please')}
          {''}{' '}
          <a href={href} style={{ textDecoration: 'underline' }} target='_blank' rel='noreferrer'>
            {t('turn on the cloud backup')}
          </a>
        </div>
      ) : null}
    </>
  )
}

export default TipsBackup
