import useMediaQuery from '@mui/material/useMediaQuery'

// ** Layout Imports
// !Do not remove this Layout import
import Layout from 'src/@core/layouts/Layout'

// ** Navigation Imports
import VerticalNavItems from 'src/navigation/vertical'
import gcloudNavigation from 'src/navigation/vertical/gcloud'
import LCloudNavigation from 'src/navigation/vertical/local'

import { useRouter } from 'next/router'

import HorizontalNavItems from 'src/navigation/horizontal'

// ** Component Import
// Uncomment the below line (according to the layout type) when using server-side menu
// import ServerSideVerticalNavItems from './components/vertical/ServerSideNavItems'
// import ServerSideHorizontalNavItems from './components/horizontal/ServerSideNavItems'

import VerticalAppBarContent from './components/vertical/AppBarContent'
import HorizontalAppBarContent from './components/horizontal/AppBarContent'

// ** Hook Import
import { useSettings } from 'src/@core/hooks/useSettings'

const UserLayout = ({ children, contentHeightFixed }) => {
  const router = useRouter()

  // 隐藏侧边栏
  if (router.asPath == '/' || router.asPath.substring(0, 13) == '/?accessToken') {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useSettings().settings.navHidden = true
  }

  // ** Hooks
  const { settings, saveSettings } = useSettings()

  let navItems = VerticalNavItems

  if (typeof window !== 'undefined') {
    if (
      window.location.pathname === '/android/classification' ||
      window.location.pathname === '/android/local/setup' ||
      window.location.pathname.substring(3) === '/android/classification' ||
      window.location.pathname.substring(3) === '/android/local/setup' ||
      window.location.pathname === '/android/step/pricing' ||
      window.location.pathname.substring(3) === '/android/step/pricing' ||
      window.location.pathname.split('/')[2] === 'step' ||
      window.location.pathname.split('/')[3] === 'step'
    ) {
      navItems = []
    } else if (router.asPath == '/pages/account-settings/account') {
      if (localStorage.getItem('currentMode') == 'Android-Cloud') {
        navItems = gcloudNavigation()
      } else if (localStorage.getItem('currentMode') == 'Android-Local') {
        navItems = LCloudNavigation()
      } else {
        navItems = VerticalNavItems()
      }
    } else if (localStorage.getItem('currentMode') == 'Android-Cloud') {
      navItems = gcloudNavigation()
    } else if (localStorage.getItem('currentMode') == 'Android-Local') {
      navItems = LCloudNavigation()
    } else {
      navItems = VerticalNavItems()
    }
  }

  // ** Vars for server side navigation
  // const { menuItems: verticalMenuItems } = ServerSideVerticalNavItems()
  // const { menuItems: horizontalMenuItems } = ServerSideHorizontalNavItems()
  /**
   *  The below variable will hide the current layout menu at given screen size.
   *  The menu will be accessible from the Hamburger icon only (Vertical Overlay Menu).
   *  You can change the screen size from which you want to hide the current layout menu.
   *  Please refer useMediaQuery() hook: https://mui.com/material-ui/react-use-media-query/,
   *  to know more about what values can be passed to this hook.
   *  ! Do not change this value unless you know what you are doing. It can break the template.
   */
  const hidden = useMediaQuery(theme => theme.breakpoints.down('lg'))
  if (hidden && settings.layout === 'horizontal') {
    settings.layout = 'vertical'
  }

  return (
    <Layout
      hidden={hidden}
      settings={settings}
      saveSettings={saveSettings}
      contentHeightFixed={contentHeightFixed}
      verticalLayoutProps={{
        navMenu: {
          navItems: navItems
        },
        appBar: {
          content: props => (
            <VerticalAppBarContent
              hidden={hidden}
              settings={settings}
              saveSettings={saveSettings}
              toggleNavVisibility={props.toggleNavVisibility}
            />
          )
        }
      }}
      {...(settings.layout === 'horizontal' && {
        horizontalLayoutProps: {
          navMenu: {
            navItems: HorizontalNavItems()
          },
          appBar: {
            content: () => <HorizontalAppBarContent hidden={hidden} settings={settings} saveSettings={saveSettings} />
          }
        }
      })}
    >
      {children}
    </Layout>
  )
}

export default UserLayout
