const navigation = () => {
  return [
    {
      "title": "Dashboard",
      "icon": "/images/navigation/dashboard.svg",
      "path": "/dashboard"
    },
    {
      "sectionTitle": "Basic"
    },
    {
      "title": "Photo",
      "icon": "/images/navigation/photo.svg",
      "path": "/photos",
      "category": "ALBUM_SUMMARY"
    },
    {
      "title": "Video",
      "icon": "/images/navigation/video.svg",
      "path": "/video",
      "category": "ALBUM_DETAIL"
    },
    {
      "title": "GPS location",
      "icon": "/images/navigation/gps-location.svg",
      "path": "/locations/gps",
      "category": "LOCATION_DEVICES"
    },
    {
      "title": "Contact",
      "icon": "/images/navigation/contact.svg",
      "path": "/contacts",
      "category": "CONTACT"
    },
    {
      "title": "Mail",
      "icon": "/images/navigation/mail.svg",
      "path": "/mail"
    },
    {
      "title": "iCloud drive",
      "icon": "/images/navigation/icloud-drive.svg",
      "path": "/i-cloud-drive",
      "category": "ICLOUD_DRIVE_DIR"
    },
    {
      "title": "Note",
      "icon": "/images/navigation/note.svg",
      "path": "/notes",
      "category": "NOTE"
    },
    {
      "title": "Calendar",
      "icon": "/images/navigation/calendar.svg",
      "path": "/calendars",
      "category": "CALENDAR"
    },
    {
      "title": "Reminder",
      "icon": "/images/navigation/reminder.svg",
      "path": "/reminders",
      "category": "REMINDER_SUMMARY"
    },
    {
      "sectionTitle": "Advanced"
    },
    {
      "title": "Text message",
      "icon": "/images/navigation/text-message.svg",
      "path": "/text-messages",
      "category": "SMS_CHAT_SUMMARY"
    },
    {
      "title": "Call",
      "icon": "/images/navigation/call.svg",
      "path": "/calls",
      "category": ""
    },
    {
      "title": "Voicemail",
      "icon": "/images/navigation/voicemail.svg",
      "path": "/voicemail",
      "category": ""
    },
    {
      "title": "WhatsApp",
      "icon": "/images/navigation/whatsapp.svg",
      "path": "/whatsapp",
      "category": "WHATSAPP_CHAT_SUMMARY"
    },
    {
      "title": "WA BUSINESS",
      "icon": "/images/navigation/wa-business.svg",
      "path": "/wa-business",
      "category": "WHATSAPP_CHAT_SUMMARY"
    },
    {
      "title": "LINE",
      "icon": "/images/navigation/line.svg",
      "path": "/line",
      "category": "LINE_CHAT_SUMMARY"
    },
    {
      "title": "Kik",
      "icon": "/images/navigation/kik.svg",
      "path": "/kik",
      "category": "KIK_CHAT_SUMMARY"
    },
    {
      "title": "WeChat",
      "icon": "/images/navigation/wechat.svg",
      "path": "/we-chat",
      "category": "WECHAT_CHAT_SUMMARY"
    },
    {
      "title": "Messenger",
      "icon": "/images/navigation/messenger.svg",
      "path": "/messenger",
      "category": "FACEBOOK_MESSENGER_SUMMARY"
    },
    {
      "title": "Browser history",
      "icon": "/images/navigation/browser-history.svg",
      "path": "/browser-history",
      "category": "SAFARI_HISTORY"
    },
    {
      "title": "Browser bookmark",
      "icon": "/images/navigation/browser-bookmark.svg",
      "path": "/browser-bookmark",
      "category": "SAFARI_BOOKMARK"
    },
    {
      "title": "Installed app",
      "icon": "/images/navigation/installed-app.svg",
      "path": "/apps",
      "category": "INSTALLED_APP"
    },
    {
      "title": "Wi-Fi network",
      "icon": "/images/navigation/wi-fi-network.svg",
      "path": "/wifi-networks",
      "category": "WIFI"
    },
    {
      "title": "Skype",
      "icon": "/images/navigation/skype.svg",
      "path": "/skype",
      "category": "skype"
    },
    {
      "title": "Teams",
      "icon": "/images/navigation/teams.svg",
      "path": "/teams",
      "category": "Teams"
    },
    {
      "title": "Telegram",
      "icon": "/images/navigation/telegram.svg",
      "path": "/telegram",
      "category": "Telegram"
    },
    {
      "title": "Snapchat",
      "icon": "/images/navigation/snapchat.svg",
      "path": "/snapchat",
      "category": "Snapchat"
    },
    {
      "title": "Instagram",
      "icon": "/images/navigation/instagram.svg",
      "path": "/instagram",
      "category": "Instagram"
    },
    {
      "title": "Facetime",
      "icon": "/images/navigation/facetime.svg",
      "path": "/facetime",
      "category": "Facetime"
    },
    {
      "title": "My account",
      "icon": "/images/navigation/my-account.svg",
      "path": "/pages/account-settings/account"
    }
  ]

}

export default navigation
