import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { getConnectionStatus, setConnectionStatus } from 'src/store/apps/Common'

const end = val => {
  if (!val || val == 'en' || val == 'kr') {
    return ''
  } else if (val == 'it-ch') {
    return '-it'
  } else {
    return '-' + val
  }
}

const DisconnectDetection = () => {
  const [isShow, setIsShow] = useState(false)

  const store = useSelector(state => state.Common)
  const dispatch = useDispatch()
  const router = useRouter()
  const { t } = useTranslation('common')

  // 检测设备是否断开
  useEffect(() => {
    // 设备切换时，触发
    const currentMode = localStorage.getItem('currentMode')
    if (currentMode === 'Android-Local' && router.pathname.includes('/android/local')) {
      dispatch(getConnectionStatus())
    } else {
      dispatch(setConnectionStatus(false))
    }
  }, [dispatch, router.pathname])

  return (
    <>
      {store && store.isDisconnect && (
        <div className='bg-[#FFF] drop-shadow-[0_0_12px_rgba(0,0,0,0.13)] rounded-[10px] pt-[18px] px-[18px] pb-[21px] mb-[20px]'>
          <div className='w-full flex items-start'>
            <Image src='/images/common/warning.svg' width={22} height={22} alt='warning'></Image>
            <div className='ml-[17px]'>
              <h2 className='text-[13px] text-[#222222] font-[600]'>{t('Childs phone stopped transmitting data')}</h2>
              <p className='text-[12px] text-[#999999] mt-[4px] mb-[5px]'>
                {t('The phone you are monitoring stopped transmitting data to your SpyX account on Jun21 2024 931 AM')}
              </p>
              {!isShow && (
                <div className='flex items-center cursor-pointer' onClick={() => setIsShow(true)}>
                  <span className='text-[13px] text-[#5035FF] font-[600] mr-[5px]'>{t('Show more')}</span>
                  <Image src='/images/common/drop-down2.svg' width={12} height={12} alt='drop down'></Image>
                </div>
              )}
              {isShow && (
                <div className='flex items-center cursor-pointer' onClick={() => setIsShow(false)}>
                  <span className='text-[13px] text-[#5035FF] font-[600] mr-[5px]'>{t('Show less')}</span>
                  <Image src='/images/common/pull-up2.svg' width={12} height={12} alt='pull-up'></Image>
                </div>
              )}
            </div>
          </div>
          {/* 隐藏项 */}
          {isShow && (
            <div className='mt-[18px]'>
              <div className='w-full bg-[#F5F6F7] rounded-[5px] p-[13px]'>
                <h2 className='text-[18px] text-[#282938] font-[600]'>{t('Why')}?</h2>
                <div className='text-[#333333] text-[12px]'>
                  <p className='text-[13px] mt-[3px] mb-[10px]'>
                    {t('This can happen due to one of the following reasons')}:
                  </p>
                  <div className='flex mb-[9px]'>
                    <div className='w-[4px] h-[4px] rounded-[50%] bg-[#333333] m-[7px]'></div>
                    <div className='flex-1'>
                      {t('The phone has not been connected to the internet for more than 24 hours')}
                    </div>
                  </div>
                  <div className='flex mb-[9px]'>
                    <div className='w-[4px] h-[4px] rounded-[50%] bg-[#333333] m-[7px]'></div>
                    <div className='flex-1'>{t('The phone was turned off for more than 24 hours')}</div>
                  </div>
                  <div className='flex mb-[9px]'>
                    <div className='w-[4px] h-[4px] rounded-[50%] bg-[#333333] m-[7px]'></div>
                    <div className='flex-1'>{t('Your kid turned on the battery saving mode that stopped SpyX')}</div>
                  </div>
                  <div className='flex'>
                    <div className='w-[4px] h-[4px] rounded-[50%] bg-[#333333] m-[7px]'></div>
                    <div className='flex-1'>{t('Antivirus removed SpyX')}</div>
                  </div>
                </div>
              </div>

              <div className='w-full bg-[#F5F6F7] rounded-[5px] p-[13px] mt-[10px]'>
                <h2 className='text-[18px] text-[#282938] font-[600] mb-[10px]'>{t('How do I fix this')}?</h2>
                <div className='text-[#333333] text-[12px]'>
                  <div className='flex mb-[9px]'>
                    <div className='w-[4px] h-[4px] rounded-[50%] bg-[#333333] m-[7px]'></div>
                    <div className='flex-1'>
                      {t('Make sure the phone is switched on and connected to Wi-Fi or mobile data connection LTE')}
                    </div>
                  </div>
                  <div className='flex mb-[9px]'>
                    <div className='w-[4px] h-[4px] rounded-[50%] bg-[#333333] m-[7px]'></div>
                    <Link
                      href={`/guide/how-to-add-spyx-to-battery-saving-exceptions-android${end(router.locale)}`}
                      locale={router.locale == 'kr' ? 'en' : router.locale}
                      target='_blank'
                      className='flex-1 text-[#2405F2]'
                    >
                      {t('Adjust the battery saving mode')}
                    </Link>
                  </div>
                  <div className='flex mb-[9px]'>
                    <div className='w-[4px] h-[4px] rounded-[50%] bg-[#333333] m-[7px]'></div>
                    <div className='flex-1'>{t('Restart the phone wait for 30 mins then refresh this page')}</div>
                  </div>
                  <div className='flex'>
                    <div className='w-[4px] h-[4px] rounded-[50%] bg-[#333333] m-[7px]'></div>
                    <div className='flex-1'>
                      {t('If this alert is still here it means you have to')}{' '}
                      <Link
                        href={`/guide/how-do-i-reinstall-spyx-android${end(router.locale)}`}
                        locale={router.locale == 'kr' ? 'en' : router.locale}
                        target='_blank'
                        className='text-[#2405F2]'
                      >
                        {t('re-install SpyX')}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

              <div className='w-full bg-[#F5F6F7] rounded-[5px] py-[10px] px-[12px] mt-[10px] flex items-center'>
                <Image src='/images/common/re-install.svg' width={50} height={50} alt='re install'></Image>
                <p className='ml-[15px] text-[12px] text-[#333333]'>
                  {t('In case you cant wait for 30 mins')}{' '}
                  <Link
                    href={`/guide/how-do-i-reinstall-spyx-android${end(router.locale)}`}
                    locale={router.locale == 'kr' ? 'en' : router.locale}
                    target='_blank'
                    className='text-[#2405F2]'
                  >
                    {t('reinstall SpyX right away')}
                  </Link>
                </p>
              </div>

              <div className='w-full bg-[#F5F6F7] rounded-[5px] py-[10px] px-[12px] mt-[10px] flex items-center'>
                <Image src='/images/common/follow.svg' width={50} height={50} alt='follow'></Image>
                <p className='ml-[15px] text-[12px] text-[#333333]'>
                  {t('If the antivirus is detecting SpyX')}{' '}
                  <Link
                    href={`/guide/how-do-i-avoid-antivirus-detection-android${end(router.locale)}`}
                    locale={router.locale == 'kr' ? 'en' : router.locale}
                    target='_blank'
                    className='text-[#2405F2]'
                  >
                    {t('follow this guide')}
                  </Link>
                </p>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  )
}

export default DisconnectDetection
