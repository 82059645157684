import React from 'react'
import axios from 'axios'
import { errorPageUpload } from 'src/@api/dashboard'


const ignores = ["Failed to execute 'removeChild' on 'Node': The node to be removed is not a child of this node.", "removeChild@[native code]"]

const ignorePatterns = [
    /Failed to execute 'removeChild'/,
    /removeChild@\[\native code\]/
]
class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props)
        this.state = { hasError: false, error: null }
    }

    static getDerivedStateFromError(error) {
        return { hasError: true, error }
    }

    componentDidCatch(error, errorInfo) {
        const asPath = this.props.asPath // 获取当前页面路径

        if (this.state.error?.message) {

            // const shouldIgnore = ignorePatterns.some(pattern => pattern.test(this.state.error?.message))
            const shouldIgnore = ignores.includes(this.state.error.message)

            console.log('this.state.error.message:::', this.state.error.message, shouldIgnore)
            if (!shouldIgnore) this.sendErrorInfo(asPath, error) // 发送错误信息到服务器
        }
    }

    async sendErrorInfo(page, error) {
        try {
            const errorStack = error.stack || ['未定位到的错误', '...']

            // 使用 API 路由发送错误信息获取源代码报错行
            const { data: response } = await axios.post('/api/error-report', {
                page,
                errorStack
            })

            const query = {
                page: response.page,
                errmsg: response.errmsg
            }
            console.log('response:::', response)
            console.log('query:::', query)

            // 解析完成，发送错误信息到记录api
            await errorPageUpload(query)
        } catch (err) {
            console.error('发送错误信息失败:', err)
        }
    }

    render() {
        if (this.state.hasError) {
            if (this.state.error.message !== "Failed to execute 'removeChild' on 'Node': The node to be removed is not a child of this node.") {
                return <h1 className="!h-[calc(var(--vh2)*100-172px)] flex justify-center items-center">This module is under maintenance...</h1>
            } else {
                return <h1 className="!h-[calc(var(--vh2)*100-172px)] flex justify-center items-center">Please click the avatar in the upper right corner to switch the language and refresh the page. Do not use the page translation directly, which will cause errors...</h1>
            }
        }

        return this.props.children
    }
}

export default ErrorBoundary