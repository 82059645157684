import * as React from 'react'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'

import { styled } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardMedia from '@mui/material/CardMedia'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import Collapse from '@mui/material/Collapse'
import Avatar from '@mui/material/Avatar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import { red } from '@mui/material/colors'

import ShareIcon from '@mui/icons-material/Share'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import Image from 'next/image'
import { useEffect, useState } from 'react'
import { Icon } from '@iconify/react'
import Stack from '@mui/material/Stack'

import Snackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert'

import SettingsBindDevice from 'src/@core/components/settings-bind-device'
import UpdateCloud from 'src/@core/components/update-cloud'

import { Modal, Button, message, Table } from 'antd'

import { useDispatch, useSelector } from 'react-redux'
import { fetchDeviceUser } from 'src/store/apps/Common'

import { getBackupDeviceList, checkCoupon, getLocalAndroidAll, getGcloudAll } from 'src/@api/dashboard'
import { getProduct, sendRenew, checkCoupons, getNotBindOrder, getAllUserDevice } from 'src/@api/login'

import { Select } from 'antd'

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { messageInfo } from 'src/components/Message'

dayjs.extend(utc)

const conversionUtcDate = (date, type) => {
  if (type === 'local') {
    // 传入local则将UTC时间转换为本地时间
    return dayjs.utc(date).local().format('YYYY-MM-DD HH:mm:ss')
  } else if (type === 'UTC') {
    // 传入UTC则将时间转换为UTC时间
    return dayjs(date).utc().format()
  }
}

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
})

const ExpandMore = styled(props => {
  const { expand, ...other } = props

  return <IconButton {...other} />
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest
  })
}))

export default function RecipeReviewCard() {
  const { t } = useTranslation('common')
  const dispatch = useDispatch()

  const router = useRouter()
  const [data, setData] = useState([])

  const columns = [
    {
      title: 'Device',
      render: (text, record, index) => <a>{record.username}</a>,
      key: 'id'
    },
    {
      title: 'Switch',
      render: (text, record, index) => (
        <Button
          style={{
            marginRight: '10px',
            color: record.id == selectDeviceUser.id ? '#fff' : '',
            background: record.id == selectDeviceUser.id ? '#1677ff' : ''
          }}
          disabled={record.disabled}
          type='dashed'
          onClick={() => {
            setSelectDeviceUser(record)
          }}
          key={index}
        >
          switch this device
        </Button>
      ),
      key: 'id'
    }
  ]

  const [selectData, setSelectData] = useState({})

  const [expanded, setExpanded] = useState(false)

  // 获取优惠卷
  const [coupons, setCoupons] = useState('')

  const [selectCard, setSelectCard] = useState(0)

  // 跳转到安卓
  const onSwitchToAndroid = async device => {
    let allDeviceUser = await getAllUserDevice({})

    // let all = allDeviceUser.data.filter(row => row.platform_type.indexOf('Android') >= 0)

    let all = allDeviceUser.data

    if (all !== null && all.length > 0) {
      localStorage.setItem(
        'DeviceUser',
        JSON.stringify({
          username: device.DeviceUser.iCloud_username,
          password: device.DeviceUser.iCloud_password,
          temporary: device.DeviceUser.temporary,
          expire_time: device.DeviceUser.expire_time
        })
      )

      localStorage.setItem('allDeviceUser', JSON.stringify(all))
      dispatch(fetchDeviceUser(device.DeviceUser.iCloud_username))
      localStorage.setItem('Basic', 1)
      window.localStorage.setItem('currentMode', device.DeviceUser.platform_type)
      if (device.DeviceUser.platform_type == 'Android-Cloud') {
        setTimeout(() => {
          router.push('/android/dashboard')
        }, 1500)
      } else {
        setTimeout(() => {
          router.push('/android/local/dashboard')
        }, 1500)
      }
    } else {
      localStorage.setItem('Basic', 0)
      localStorage.setItem('DeviceUser', JSON.stringify({}))
      localStorage.setItem('allDeviceUser', JSON.stringify([]))
      setTimeout(() => {
        router.push('/android/dashboard')
      }, 1500)
    }
  }

  const handleExpandClick = i => {
    if (i == selectCard) {
      setExpanded(!expanded)

      return
    }
    setSelectCard(i)
    setExpanded(true)
  }

  const setdata = (item, index) => {
    setSelectData(item)

    localStorage.setItem('currentDevice', JSON.stringify(item))

    if (item.DeviceUser.platform_type != 'iOS-Cloud') {
      onSwitchToAndroid(item)

      return
    }

    let DeviceUser = {
      username: item.DeviceUser.iCloud_username,
      password: item.DeviceUser.iCloud_password,
      expire_time: item.DeviceUser.expire_time
    }

    localStorage.setItem('DeviceUser', JSON.stringify(DeviceUser))

    let url = window.location.href //获取当前页面的url

    //去除参数

    location.href = url.replace(/(\?|#)[^'"]*/, '')
  }

  const [open, setOpen] = React.useState(false)

  const handleClick = () => {
    setOpen(true)
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }

  let { data: userData } = JSON.parse(localStorage.getItem('userData'))
  let getDeviceUser =
    JSON.parse(localStorage.getItem('DeviceUser')) != null
      ? JSON.parse(localStorage.getItem('DeviceUser')).username
      : ''

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [product, setProduct] = useState([])

  // 产品单选
  const [currentProductSelect, setCurrentProductSelect] = useState('')

  // appid
  const [appleId, setAppleId] = useState('')
  const [secondaryAppleId, setSecondaryAppleId] = useState('')

  // 未绑定订单
  const [notBindOrder, setNotBindOrder] = useState([])

  // 设备账号为空
  const [notBindDevice, setNotBindDevice] = useState([])

  const showModal = async () => {
    let temporary = JSON.parse(localStorage.getItem('DeviceUser')).temporary
    if (temporary == '1') {
      location.href = `${process.env.NEXT_PUBLIC_CLIENT_URL}${process.env.NEXT_PUBLIC_MY_PRICING}`

      return
    }

    let currentOrder = await getNotBindOrder()

    setNotBindOrder(currentOrder.data)

    if (currentOrder.data.length > 0) {
      localStorage.setItem('ontBindOrder', JSON.stringify(currentOrder.data[0]))
      router.push('/')

      return
    } else {
      location.href = `${process.env.NEXT_PUBLIC_CLIENT_URL}/step/choose-device `
    }

    return

    setIsModalOpen(true)

    // 获取产品列表
    let res = await getProduct()

    let result = []
    if (res.data.length > 0) {
      let price = ''
      result = res.data.map(item => {
        if (item.attributes.name == '1 Month Plan') {
          price = Number(item.attributes.price) + 5 + '/mo*'
        } else if (item.attributes.name == '3 Months Plan') {
          price = Number(Number(item.attributes.price) + 4.99).toFixed(2) + '/mo*'
        } else if (item.attributes.name == '12 Months Plan') {
          price = Number(Number(item.attributes.price) + 4.99).toFixed(2) + '/mo*'
        }

        return {
          id: item.id,
          name: item.attributes.name,
          old_price: price,
          img: item.attributes.img.data.attributes.url
        }
      })
    }
    setProduct(result)
  }

  const [isShowCode, setIsShowCode] = useState(false)

  const [messageApi, contextHolder] = message.useMessage()

  useEffect(() => {
    getAllUserDevice().then(res => {
      localStorage.setItem('allDeviceUser', JSON.stringify(res.data))
    })
  }, [])

  const handleOk = async () => {
    if (coupons != '') {
      let check = await checkCoupons({ name: coupons })

      if (check.data == null) {
        messageInfo({
          content: `Invalid coupon code`,
          duration: 3
        })

        return
      }
    }

    setLoading(true)

    if (currentProductSelect == '') {
      setLoading(false)

      messageInfo('Please select a product')

      return
    }
    if (appleId == '' || secondaryAppleId == '') {
      setLoading(false)

      messageInfo('iCloud must be filled in')

      return
    }
    if (appleId != secondaryAppleId) {
      setLoading(false)

      messageInfo('iCloud inconsistent')

      return
    }

    let { data, msg } = await sendRenew({
      icloud_account: appleId,
      new: true,
      product_id: currentProductSelect,
      coupons: coupons
    })
    if (msg == 'Invalid coupon code') {
      messageInfo({
        content: `Invalid coupon code`,
        duration: 8
      })
    } else {
      messageInfo({
        content: `Device added ${data.data.orderStatus}`,
        duration: 8
      })
    }

    setIsModalOpen(false)

    setCoupons('')
    setCurrentProductSelect('')
    setAppleId('')
    setSecondaryAppleId('')
  }

  const handleCancel = () => {
    setCurrentProductSelect('')
    setCoupons('')

    setAppleId('')
    setSecondaryAppleId('')
    setLoading(false)
    setIsModalOpen(false)
  }

  const onchangeUserInput = e => {
    setAppleId(e.target.value.trim())
  }

  const onchangeSecondaryInput = e => {
    setSecondaryAppleId(e.target.value.trim())
  }

  const productSelect = e => {
    setCurrentProductSelect(e.id)
  }

  const [bindDevice, setBindDevice] = useState(false)
  const [currentOrder, setCurrentOrder] = useState({})

  // 直接添加设备
  const addDirectly = row => {
    setCurrentOrder(row)

    setBindDevice(!bindDevice)
  }

  // 修改设备
  const [updateOpen, setUpdateOpen] = useState(false)
  const [updateDeviceData, setUpdateDeviceData] = useState({})

  const updateDirectly = row => {
    setUpdateOpen(true)
    setUpdateDeviceData(row)
  }

  const resetLoadData = () => {
    setIsModalOpen(false)
    location.reload()
  }

  // 切换设备
  const [switchModalOpen, setSwitchModalOpen] = useState(false)
  const [allDeviceUser, setAllDeviceUser] = useState([])
  const [selectDeviceUser, setSelectDeviceUser] = useState({})

  const switchCancel = () => {
    setSwitchModalOpen(false)
  }

  const switchOk = () => {
    if (JSON.stringify(selectDeviceUser) == '{}') {
      setSwitchModalOpen(false)

      return
    }

    // 重新验证
    if (selectDeviceUser.password == '' || selectDeviceUser.password == null) {
      localStorage.removeItem('BasicTips')
      localStorage.removeItem('Basic')
      localStorage.removeItem('Advanced')
      localStorage.removeItem('DeviceUser')
      localStorage.removeItem(
        'currentDevice',
        JSON.stringify({
          'Build Version': '0',
          'Device Name': '',
          'Product Type': '',
          'Serial Number': '',
          'Unique Identifier': ''
        })
      )
      router.push('/dashboard')
      setSwitchModalOpen(false)

      return
    } else {
      localStorage.setItem('DeviceUser', JSON.stringify(selectDeviceUser))
      localStorage.setItem('currentDevice', JSON.stringify({}))
      if (selectDeviceUser.verify == '1') {
        window.localStorage.setItem('Basic', 1)
        window.localStorage.setItem('Advanced', 1)
      }

      setSwitchModalOpen(false)

      window.location.href = '/dashboard'
    }
  }

  const switchDevice = async () => {
    let allDeviceUser = JSON.parse(localStorage.getItem('allDeviceUser')) || []
    let DeviceUser = JSON.parse(localStorage.getItem('DeviceUser'))

    if (allDeviceUser.length > 0) {
      let result = allDeviceUser.map(item => {
        return {
          username: item.iCloud_username,
          password: item.iCloud_password,
          id: item.id,
          disabled: DeviceUser != null ? (item.iCloud_username == DeviceUser.username ? true : false) : false,
          verify: item.verify,
          expireTime: conversionUtcDate(item.expire_time, 'local')
        }
      })
      setAllDeviceUser(result)
    }
    setSwitchModalOpen(true)
  }

  // 浏览器的高度 默认设置为0；
  const [windowWidth, setWindowHeight] = useState(0)

  const resizeUpdate = e => {
    let w = e.target.innerWidth
    setWindowHeight(w)
  }

  if (windowWidth > 480) {
    columns.push({
      title: 'Expire date',
      dataIndex: 'expireTime',
      key: 'id'
    })
  }

  const [allCurrentDevice, setAllCurrentDevice] = useState([])

  useEffect(() => {
    const getOrdernotBind = async () => {
      let currentOrder = await getNotBindOrder()

      setNotBindOrder(currentOrder.data)
      setNotBindDevice(currentOrder.device)
    }
    getOrdernotBind()

    // 获取设备列表
    const getDeviceList = async () => {
      let allDeviceUser = JSON.parse(localStorage.getItem('allDeviceUser')) || []

      // if (allDeviceUser instanceof Array) {
      //   allDeviceUser = allDeviceUser.filter(row => row.platform_type === 'iOS-Cloud')
      // }

      let allCurrentDevice = []
      let isFirst = true
      for (let i = 0; i < allDeviceUser.length; i++) {
        if (allDeviceUser[i].iCloud_username != '' && allDeviceUser[i].iCloud_username != null) {
          let get
          if (allDeviceUser[i].platform_type == 'iOS-Cloud') {
            get = await getBackupDeviceList({
              username: allDeviceUser[i].iCloud_username,
              params: {
                category: 'BACKUP_DEVICE_LIST'
              }
            })
          } else if (allDeviceUser[i].platform_type == 'Android-Local') {
            if (!isFirst) continue
            get = await getLocalAndroidAll({
              username: userData.email,
              params: {
                category: 'DEVICE_LIST',
                offset: 0,
                limit: 100
              }
            })

            // 过滤设备
            if (get.data.contents) {
              const _arr = [...get.data.contents]
              get.data.contents = []
              for (const item of _arr) {
                for (const item2 of allDeviceUser) {
                  if (item.id == item2.iCloud_username) {
                    get.data.contents.push(item)
                  }
                }
              }
            }
            isFirst = false
          } else if (allDeviceUser[i].platform_type == 'Android-Cloud') {
            get = await getGcloudAll({
              username: allDeviceUser[i].iCloud_username,
              params: {
                category: 'DEVICE_LIST',
                offset: 0,
                limit: 100
              }
            })
          }
          if (get.data.contents != null) {
            get.data.contents = get.data.contents.map(item => {
              return {
                ...item,
                DeviceUser: allDeviceUser[i]
              }
            })
            allCurrentDevice.push(get.data.contents)
          } else {
            allCurrentDevice.push({ ...allDeviceUser[i], DeviceUser: allDeviceUser[i] })
          }
        }
      }

      setAllCurrentDevice(allCurrentDevice)

      let backupDeviceList = await getBackupDeviceList({
        username:
          JSON.parse(localStorage.getItem('DeviceUser')) != null
            ? JSON.parse(localStorage.getItem('DeviceUser')).username
            : '',
        params: {
          category: 'BACKUP_DEVICE_LIST'
        }
      })

      if (backupDeviceList.data.contents == null) {
        localStorage.setItem('currentDevice', JSON.stringify({}))
        localStorage.setItem('Advanced', 0)
      } else {
        setData(backupDeviceList.data.contents)

        const _currentDevice = localStorage.getItem('currentDevice')
        if (
          _currentDevice != '{}' &&
          JSON.parse(_currentDevice)?.DeviceUser?.platform_type == localStorage.getItem('currentMode')
        ) {
          setSelectData(JSON.parse(_currentDevice))
        } else {
          setSelectData(backupDeviceList.data.contents[0])
          localStorage.setItem('currentDevice', JSON.stringify(backupDeviceList.data.contents[0]))
        }
      }
    }
    getDeviceList()

    // 页面刚加载完成后获取浏览器窗口的大小
    let w = window.innerWidth
    setWindowHeight(w)

    // 页面变化时获取浏览器窗口的大小
    window.addEventListener('resize', resizeUpdate)

    return () => {
      // 组件销毁时移除监听事件
      window.removeEventListener('resize', resizeUpdate)
    }
  }, [])

  // 判断是不是需要添加设备
  const checkAddDevice = () => {
    let ontBindOrder = localStorage.getItem('ontBindOrder')

    if (ontBindOrder != null && ontBindOrder != '{}') {
      setBindDevice(true)
      setCurrentOrder(JSON.parse(ontBindOrder))
    }
  }
  useEffect(() => {
    checkAddDevice()
  }, [])

  const store = useSelector(state => state.Common)

  return (
    <>
      {allCurrentDevice.length > 0 ? (
        <>
          {allCurrentDevice.map((row, i) => {
            return (
              <>
                {row instanceof Array ? (
                  <div
                    className='flex w-full cursor-pointer'
                    key={i}
                    style={{ marginBottom: '5px' }}
                    onClick={() => handleExpandClick(i)}
                  >
                    <Card
                      className='w-[95%] mx-auto '
                      sx={{
                        backgroundColor: row[0].DeviceUser.iCloud_username === store.deviceUser ? '#FFF' : '#303771'
                      }}
                    >
                      <div className='flex justify-between py-[12px] pl-[16px] pr-[6px]'>
                        <div className='flex items-center'>
                          <Image
                            src={
                              row[0]?.DeviceUser?.platform_type === 'iOS-Cloud'
                                ? '/images/common/iCloud.svg'
                                : row[0]?.DeviceUser?.platform_type === 'Android-Local'
                                ? '/images/common/android.svg'
                                : '/images/common/google.svg'
                            }
                            alt=''
                            width={36}
                            height={36}
                            className='w-[36px] h-[36px]'
                          />
                          <ul className='ml-[14px]'>
                            <li className='flex items-center'>
                              <p
                                className={`${
                                  row[0].DeviceUser.iCloud_username === store.deviceUser
                                    ? 'text-[#222222]'
                                    : 'text-[#FFFFFF]'
                                } font-[600] text-[16px]`}
                              >
                                {' '}
                                {row[0].DeviceUser.platform_type}
                              </p>
                            </li>
                            {row[0].DeviceUser.platform_type !== 'Android-Local' && (
                              <li
                                className={`${
                                  row[0].DeviceUser.iCloud_username === store.deviceUser
                                    ? 'text-[#9198AD]'
                                    : 'text-[rgba(255,255,255,0.5)]'
                                } text-[16px] mt-[4px] Bxls:w-[200px] overflow-hidden text-ellipsis text-nowrap`}
                              >
                                {row[0].DeviceUser.iCloud_username}
                              </li>
                            )}
                          </ul>
                        </div>

                        <ExpandMore
                          expand={expanded && selectCard == i}
                          aria-expanded={expanded}
                          aria-label='show more'
                          src='/images/common/drop-down-black.svg'
                        >
                          <ExpandMoreIcon
                            sx={{
                              width: '35px',
                              height: '35px',
                              color: row[0].DeviceUser.iCloud_username === store.deviceUser ? '#222222' : '#FFF',
                              opacity: row[0].DeviceUser.iCloud_username === store.deviceUser ? 1 : 0.5
                            }}
                          />
                        </ExpandMore>
                      </div>

                      <Collapse in={expanded && selectCard == i} timeout='auto' unmountOnExit>
                        {row.map((item, index) => {
                          return (
                            <div
                              key={index}
                              className={`${
                                row[0].DeviceUser.iCloud_username === store.deviceUser &&
                                (selectData['Unique Identifier'] || selectData['id']) !==
                                  (item['Unique Identifier'] || item['id'])
                                  ? 'text-[#000]'
                                  : 'text-[#FFF]'
                              }  text-[16px] w-full py-[16px] hover:bg-[#2A99FF] hover:text-[#FFF] p-[16px]`}
                              style={{
                                background:
                                  (selectData['Unique Identifier'] || selectData['id']) ===
                                  (item['Unique Identifier'] || item['id'])
                                    ? '#2A99FF'
                                    : '',
                                cursor: 'pointer'
                              }}
                              onClick={() => setdata(item, index, row)}
                            >
                              <div className='flex'>
                                <div className='w-[36px]'></div>
                                <ul className='pl-[14px] flex-1 flex'>
                                  <li className='flex items-center flex-1 justify-between'>
                                    <p>{item['Device Name'] || item['name']}</p>

                                    {(selectData['Unique Identifier'] || selectData['id']) ===
                                      (item['Unique Identifier'] || item['id']) && (
                                      <i className='w-[8px] h-[8px] bg-[#FFF] rounded-full ml-[5px] mr-[6px]'></i>
                                    )}
                                  </li>
                                </ul>
                              </div>
                            </div>
                          )
                        })}
                      </Collapse>
                    </Card>
                  </div>
                ) : (
                  <div
                    className='flex w-full cursor-pointer'
                    key={i}
                    style={{ marginBottom: '5px' }}
                    onClick={() => setdata(row)}
                  >
                    <Card
                      className='w-[95%] mx-auto '
                      sx={{
                        backgroundColor: row.iCloud_username === store.deviceUser ? '#FFF' : '#303771'
                      }}
                    >
                      <div className='flex justify-between py-[12px] pl-[16px] pr-[6px]'>
                        <div className='flex items-center'>
                          <Image
                            src={
                              row.platform_type === 'iOS-Cloud'
                                ? '/images/common/iCloud.svg'
                                : row.platform_type === 'Android-Local'
                                ? '/images/common/android.svg'
                                : '/images/common/google.svg'
                            }
                            alt=''
                            width={36}
                            height={36}
                            className='w-[36px] h-[36px]'
                          />
                          <ul className='ml-[14px]'>
                            <li className='flex items-center'>
                              <p
                                className={`${
                                  row.iCloud_username === store.deviceUser ? 'text-[#222222]' : 'text-[#FFFFFF]'
                                } font-[600] text-[16px]`}
                              >
                                {' '}
                                {row.platform_type}
                              </p>
                            </li>
                            {row.platform_type !== 'Android-Local' && (
                              <li
                                className={`${
                                  row.iCloud_username === store.deviceUser
                                    ? 'text-[#9198AD]'
                                    : 'text-[rgba(255,255,255,0.5)]'
                                } text-[16px] mt-[4px] Bxls:w-[200px] overflow-hidden text-ellipsis text-nowrap`}
                              >
                                {row.iCloud_username}
                              </li>
                            )}
                          </ul>
                        </div>
                      </div>
                    </Card>
                  </div>
                )}
              </>
            )
          })}
        </>
      ) : null}
      {/* {localStorage.getItem('DeviceUser') !== null && localStorage.getItem('DeviceUser') !== '{}' ? (
        <>
          {notBindOrder.length > 0
            ? notBindOrder.map((row, i) => (
                <div
                  key={i}
                  className='flex w-full'
                  style={{
                    marginTop: '10px',
                    color: '#000',
                    textAlign: 'center',
                    fontSize: '14px',
                    fontWeight: '500',
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  <Card
                    className='w-[95%] mx-auto '
                    sx={{
                      backgroundColor: '#FCFDFE',
                      padding: '10px',
                      cursor: 'pointer',
                      color: '#097ae4',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                    onClick={() => addDirectly(row)}
                  >
                    <Icon icon='material-symbols:add' className='text-[#097ae4]' />
                    <span>You can also add a device</span>
                  </Card>
                </div>
              ))
            : null}
          {notBindDevice.length > 0
            ? notBindDevice.map((row, i) => (
                <div
                  key={i}
                  className='flex w-full'
                  style={{
                    marginTop: '10px',
                    color: '#000',
                    textAlign: 'center',
                    fontSize: '14px',
                    fontWeight: '500',
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  <Card
                    className='w-[95%] mx-auto '
                    sx={{
                      backgroundColor: '#FCFDFE',
                      padding: '10px',
                      cursor: 'pointer',
                      color: '#097ae4',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                    onClick={() => updateDirectly(row)}
                  >
                    <Icon icon='material-symbols:add' className='text-[#097ae4]' />
                    <span>You can also add a device</span>
                  </Card>
                </div>
              ))
            : null}
        </>
      ) : null} */}
      {/* {localStorage.getItem('deviceMany') == 'true' ? (
        <Stack
          spacing={2}
          sx={{
            width: 'calc(100% - 30px)',
            margin: '0 auto',
            border: '1px solid #666786',
            borderRadius: '10px',
            height: '56px',
            marginTop: '10px'
          }}
        >
          <div className='flex items-center w-11/12 justify-center pt-1 cursor-pointer'>
            <div>
              <Icon icon='icon-park-outline:switch' className='text-[#fff]' />
            </div>
            <div className='ml-2'>
              <span className='text-[1rem] text-[#fff] font-[500]' onClick={onSwitchToAndroid}>
                {t('Switch to Android')}
              </span>
            </div>
          </div>
        </Stack>
      ) : null} */}
      <div
        className='w-[95%] !h-[36px] mx-auto rounded-[8px] flex items-center justify-center cursor-pointer mt-[11px] mb-[13px]'
        onClick={showModal}
      >
        <div className='w-full h-[36px] flex justify-center items-center'>
          <Icon icon='material-symbols:add' className='text-[#FFCF55] text-[20px]' />
          <span className='text-[16px] text-[#FFCF55] ml-[6px]'>{t('Add new device')}</span>
        </div>
      </div>
      <Modal
        className='equipment'
        width={800}
        open={isModalOpen}
        closable={false}
        footer={[
          <Button key='back' onClick={handleCancel}>
            cancel
          </Button>,

          <Button
            key='submit'
            type='primary'
            onClick={handleOk}
            style={{ background: '#1677ff', color: '#fff' }}
            loading={loading}
          >
            ok
          </Button>
        ]}
        zIndex={1200}
      >
        <div
          style={{
            background: '#E7E7E7',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '0 10px',
            borderTopRightRadius: '8px',
            borderTopLeftRadius: '8px'
          }}
        >
          <h2 style={{ color: '#000000', fontSize: '22px' }}>Add New Device</h2>
          <Icon
            icon='ic:round-cancel'
            color='#494949'
            style={{ fontSize: '18px', cursor: 'pointer' }}
            onClick={handleCancel}
          />
        </div>

        <div
          style={{
            margin: '10px 0',
            fontSize: '18px',
            marginBottom: '10px',
            padding: '10px',
            borderBottom: '1px solid #E3E3E8'
          }}
        >
          Choose your product
        </div>

        <Grid style={{ padding: '10px' }}>
          <Grid container spacing={6}>
            {product.map((row, index) => (
              <Grid key={index} item xs={12} md={12} lg={4} onClick={() => productSelect(row)}>
                <Card style={{ cursor: 'pointer', background: currentProductSelect == row.id ? '#DAE1E6' : '' }}>
                  <CardContent>
                    <p style={{ textAlign: 'center', fontSize: '18px' }}>{row.name}</p>
                    <div style={{ display: 'flex', justifyContent: 'center', margin: '10px 0' }}>
                      <img src={row.img} alt='1' style={{ width: '120px' }} />
                    </div>
                    <p style={{ textAlign: 'center' }}>{row.old_price}</p>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid>

        <p style={{ textAlign: 'center', marginTop: '40px' }}>
          Enter your iCloud credentials of the device you want to add
        </p>

        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '26px 0' }}>
          <input
            style={{
              width: '340px',
              height: '40px',
              background: '#fff',
              borderTop: '1px solid #d6d6d6',
              borderRight: '1px solid #d6d6d6',
              borderLeft: '1px solid #d6d6d6',
              borderTopLeftRadius: '6px',
              borderTopRightRadius: '6px',
              color: '#494949',
              padding: '0 15px',
              fontSize: '18px'
            }}
            value={appleId}
            onChange={e => onchangeUserInput(e)}
            placeholder='Apple ID'
          />
          <input
            style={{
              width: '340px',
              height: '40px',
              background: '#fff',
              border: '1px solid #d6d6d6',
              borderBottomLeftRadius: '6px',
              borderBottomRightRadius: '6px',
              color: '#494949',
              padding: '0 15px',
              fontSize: '18px'
            }}
            value={secondaryAppleId}
            onChange={e => onchangeSecondaryInput(e)}
            placeholder='Secondary input Apple ID'
          />

          {!isShowCode ? (
            <button
              style={{
                fontSize: '16px',
                width: '340px',
                textAlign: 'left',
                padding: '10px 0px',
                background: '#07273D',
                height: '40px',
                marginTop: '10px',
                color: '#fff',
                textAlign: 'center',
                borderRadius: '10px',
                lineHeight: '20px',
                fontWeight: '500'
              }}
              onClick={() => {
                setIsShowCode(!isShowCode)
              }}
            >
              I have a coupon code
            </button>
          ) : null}

          {isShowCode ? (
            <div style={{ width: '340px', display: 'flex' }}>
              <input
                style={{
                  width: '200px',
                  height: '40px',
                  background: '#fff',
                  border: '1px solid #d6d6d6',
                  borderBottomLeftRadius: '6px',
                  borderBottomRightRadius: '6px',
                  color: '#494949',
                  padding: '0 15px',
                  fontSize: '18px',
                  marginTop: '10px'
                }}
                value={coupons}
                onChange={e => setCoupons(e.target.value.trim())}
                placeholder='use coupon code'
              />

              <button
                style={{
                  fontSize: '16px',
                  width: '70px',
                  marginLeft: '5px',
                  textAlign: 'left',
                  padding: '10px 0px',
                  background: '#07273D',
                  height: '40px',
                  marginTop: '10px',
                  color: '#fff',
                  textAlign: 'center',
                  borderRadius: '10px',
                  lineHeight: '20px',
                  fontWeight: '500'
                }}
                onClick={async () => {
                  let data = await checkCoupon({ name: coupons })
                  if (JSON.stringify(data.data) == '{}') {
                    setCoupons('')
                    messageInfo({
                      content: `Invalid coupon code`,
                      duration: 3
                    })
                  } else {
                    messageInfo({
                      content: `Coupon code verification successful`,
                      duration: 3
                    })
                  }
                }}
              >
                APPLY
              </button>
              <button
                style={{
                  fontSize: '16px',
                  width: '70px',
                  marginLeft: '5px',
                  textAlign: 'left',
                  padding: '10px 0px',
                  border: '1px solid #B5B5B5',
                  color: '#07273D',
                  height: '40px',
                  marginTop: '10px',
                  textAlign: 'center',
                  borderRadius: '10px',
                  lineHeight: '20px',
                  fontWeight: '500'
                }}
                onClick={() => {
                  setCoupons('')
                  setIsShowCode(false)
                }}
              >
                CANCEL
              </button>
            </div>
          ) : null}
        </div>
      </Modal>
      <Modal
        className='equipment'
        width={550}
        open={switchModalOpen}
        closable={false}
        footer={[
          <Button key='back' onClick={switchCancel}>
            cancel
          </Button>,

          <Button key='submit' type='primary' onClick={switchOk} style={{ background: '#1677ff', color: '#fff' }}>
            ok
          </Button>
        ]}
      >
        <div style={{ minHeight: '300px' }}>
          <div
            style={{
              background: '#E7E7E7',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: '0 10px',
              borderTopRightRadius: '8px',
              borderTopLeftRadius: '8px'
            }}
          >
            <h2 style={{ color: '#000000', fontSize: '22px' }}>switch device</h2>
            <Icon
              icon='ic:round-cancel'
              color='#494949'
              style={{ fontSize: '18px', cursor: 'pointer' }}
              onClick={switchCancel}
            />
          </div>

          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px' }}>
            <Icon
              icon='ic:baseline-published-with-changes'
              color='#1BC4BD'
              style={{ fontSize: '68px', cursor: 'pointer' }}
              onClick={switchCancel}
            />
          </div>

          <p style={{ padding: '4px 64px', fontSize: '16px' }}>
            If you need to rebind, please contact: {process.env.NEXT_PUBLIC_CONNECT_EMAIL}
          </p>
          <p style={{ borderBottom: '1px solid #E3E3E8', padding: '0px 64px', paddingBottom: '14px' }}>
            ps: bind 5 times for free
          </p>
          <div style={{ padding: '10px' }}>
            <Table columns={columns} dataSource={allDeviceUser} pagination={false} />
          </div>
        </div>
      </Modal>
      {bindDevice ? <SettingsBindDevice order={currentOrder} resetLoadData={resetLoadData} /> : null}
      {updateOpen ? <UpdateCloud device={updateDeviceData} updateOpen={updateOpen} /> : null}
      {contextHolder}
    </>
  )
}
