import axios from '/src/utils/request'
import qs from 'qs'

/**
 * 登录接口
 */

const getUserInfo = () => axios.get('/api/definition/user-info')

// 设备登录
const authByDevice = options => axios.post('/api/dashboard/by-device', options)

// 设备登录验证码
const authVerifyByDevice = options => axios.post('/api/dashboard/verify/by-device', options)

// 下载数据
const downloadWeb = options => axios.post('/api/dashboard/download/web', options)

// 触发短信验证码
const bySms = options => axios.post('/api/dashboard/by-sms', options)

// 短信验证
const authVerifyBySms = options => axios.post('/api/dashboard/verify/by-sms', options)

// 获取设备 ID 信息
const authInfo = options => axios.post('/api/dashboard/auth/auth-info', options)

// 绑定设备
const userBindingDevice = options => axios.post('/api/definition/user-binding-device', options)

// 订单绑定设备

const orderBindingDevice = options => axios.post('/api/definition/order-binding-device', options)

// 获取个人中心订单

const getUserAccountDevice = options => axios.get('/api/definition/user-account-device?' + qs.stringify(options))

// 获取个人中心设备

const getUserAccountOrder = options => axios.get('/api/definition/user-account-order?' + qs.stringify(options))

// 开启或者关闭续费
const sendWhetherToRenew = options => axios.post('/api/definition/whether-to-renew', options)

// 关闭续费并发送邮件
const sendWhetherToRenewClose = options => axios.get('/api/definition/whether-to-renew-close?' + qs.stringify(options))

// 手动续费
const sendRenew = options => axios.post('/api/renew', options)

// 通知
const getNotifications = options => axios.get('/api/definition/user-notifications')

// 通知列表

const getNotificationsList = options => axios.get('/api/definition/user-notifications-list?' + qs.stringify(options))

// 通知信息已读
const sendReadNotifications = options => axios.post('/api/definition/read-notifications', options)

// 获取所有产品价格

const getProduct = option => axios.get('/api/tsafely-products?populate=img&pagination[page]=1&pagination[pageSize]=3')

// 检查下载时间
const checkDownloadTime = options => axios.post('/api/definition/check-download-time', options)

// 修改下载时间
const sendDownloadTime = options => axios.post('/api/definition/send-download-time', options)

// 获取用户所有设备
const getAllUserDevice = options => axios.get('/api/definition/user-device?' + qs.stringify(options))

// 获取设备更新时间
const getDeviceResetTime = options => axios.post('/api/definition/user-device-reset-time', options)

// 获取用户优惠价
const checkCoupons = data => axios.post('/api/user/coupon', data)

// 未绑定订单
const getNotBindOrder = data => axios.post('/api/definition/not-bind-order', data)

// 修改密码
const updatePassword = data => axios.post('/api/definition/updatePassword', data)

// 修改icloud
const updateIcloud = data => axios.post('/api/user/update-cloud', data)

// 获取当前设备
const userDeviceCurrent = options => axios.get('/api/definition/user-device-current')

// 校验当前绑定类型
const verifyCurrentType = option => axios.get('/api/definition/user-order-current')

// 登录
const gcloudAuth = options => axios.post('/api/gcloud/dashboard/auth', options)

// 验证
const gcloudVerify = options => axios.post('/api/gcloud/dashboard/verify', options)

// 下载
const gcloudDownload = options => axios.post('/api/gcloud/dashboard/download', options)

// 机器人
const gcloudVerifyBg = options => axios.post('/api/gcloud/auth/verify/bg', options)

// 解绑设备

const currentUnBindDevice = options => axios.post('/api/device/unbind', options)

// 收集安卓错误
const addAndroidDeviceVerifyInfo = options => axios.post('/api/device-verify-info', options)
const getAndroidDeviceVerifyInfo = options => axios.get('/api/device-verify-info')

// 收集日志
const sendUserAnalytic = option => axios.post('/api/user-analytic', option)

// 添加设备布局
const sendLikeType = option => axios.post('/api/device/like-type', option)

// 谷歌云登陆
const googleSignup = option => axios.post('/api/gcloud/signin', option)

// 谷歌云登陆验证
const googleCodeSubmit = option => axios.post('/api/gcloud/signin/code/submit', option)

// 轮训谷歌云登陆状态
const googleStatusQuery = option => axios.post('/api/gcloud/signin/status/query', option)

export {
  getUserInfo,
  authByDevice,
  authVerifyByDevice,
  downloadWeb,
  bySms,
  authVerifyBySms,
  authInfo,
  userBindingDevice,
  orderBindingDevice,
  getUserAccountDevice,
  getUserAccountOrder,
  sendWhetherToRenew,
  sendWhetherToRenewClose,
  sendRenew,
  getNotifications,
  getNotificationsList,
  sendReadNotifications,
  getProduct,
  checkDownloadTime,
  sendDownloadTime,
  getAllUserDevice,
  getDeviceResetTime,
  checkCoupons,
  getNotBindOrder,
  updatePassword,
  updateIcloud,
  userDeviceCurrent,
  verifyCurrentType,
  gcloudAuth,
  gcloudVerify,
  gcloudDownload,
  gcloudVerifyBg,
  currentUnBindDevice,
  addAndroidDeviceVerifyInfo,
  getAndroidDeviceVerifyInfo,
  sendUserAnalytic,
  sendLikeType,
  googleSignup,
  googleCodeSubmit,
  googleStatusQuery
}
