// ** Next Imports
import Head from 'next/head'
import { Router, useRouter } from 'next/router'

// ** Store Imports
import { store } from 'src/store'
import { Provider } from 'react-redux'

// ** Loader Import
import NProgress from 'nprogress'

// ** Emotion Imports
import { CacheProvider } from '@emotion/react'

// ** Config Imports
import 'src/configs/i18n'
import { defaultACLObj } from 'src/configs/acl'
import themeConfig from 'src/configs/themeConfig'

// ** Fake-DB Import
// import 'src/@fake-db'

// ** Third Party Import
import { Toaster } from 'react-hot-toast'

// vercel
import { Analytics } from '@vercel/analytics/react'

// api
import { sendUserAnalytic } from 'src/@api/login'

// ** Component Imports
import UserLayout from 'src/layouts/UserLayout'
import AclGuard from 'src/@core/components/auth/AclGuard'
import ThemeComponent from 'src/@core/theme/ThemeComponent'
import AuthGuard from 'src/@core/components/auth/AuthGuard'
import GuestGuard from 'src/@core/components/auth/GuestGuard'
import WindowWrapper from 'src/@core/components/window-wrapper'

// ** Spinner Import
import Spinner from 'src/@core/components/spinner'

// ** Contexts
import { AuthProvider } from 'src/context/AuthContext'
import { SettingsConsumer, SettingsProvider } from 'src/@core/context/settingsContext'

// ** Styled Components
import ReactHotToast from 'src/@core/styles/libs/react-hot-toast'

import { appWithTranslation } from 'next-i18next'

// ** Utils Imports
import { createEmotionCache } from 'src/@core/utils/create-emotion-cache'

// ** Prismjs Styles
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import 'prismjs/components/prism-jsx'
import 'prismjs/components/prism-tsx'

// ** React Perfect Scrollbar Style
import 'react-perfect-scrollbar/dist/css/styles.css'
import 'src/iconify-bundle/icons-bundle-react'

// ** Global css styles
import '../../styles/globals.css'
import Script from 'next/script'

import { useEffect } from 'react'
import { isAllOf } from '@reduxjs/toolkit'
import ErrorBoundary from 'src/components/ErrorBoundary'

const clientSideEmotionCache = createEmotionCache()

// ** Pace Loader
if (themeConfig.routingLoader) {
  Router.events.on('routeChangeStart', () => {
    NProgress.start()
  })
  Router.events.on('routeChangeError', () => {
    NProgress.done()
  })
  Router.events.on('routeChangeComplete', () => {
    NProgress.done()
  })
}

const Guard = ({ children, authGuard, guestGuard }) => {
  if (guestGuard) {
    return <GuestGuard fallback={<Spinner />}>{children}</GuestGuard>
  } else if (!guestGuard && !authGuard) {
    return <>{children}</>
  } else {
    return <AuthGuard fallback={<Spinner />}>{children}</AuthGuard>
  }
}

// ** Configure JSS & ClassName
const App = props => {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props

  // Variables
  const contentHeightFixed = Component.contentHeightFixed ?? false

  const getLayout =
    Component.getLayout ?? (page => <UserLayout contentHeightFixed={contentHeightFixed}>{page}</UserLayout>)
  const setConfig = Component.setConfig ?? undefined
  const authGuard = Component.authGuard ?? true
  const guestGuard = Component.guestGuard ?? false
  const aclAbilities = Component.acl ?? defaultACLObj

  const { asPath, query, pathname } = useRouter()
  const router = useRouter()

  useEffect(() => {
    localStorage.setItem('duration', new Date())
  }, [query])

  //记录停留时间
  useEffect(() => {
    const handleBeforeHistoryChange = async e => {
      const leave = ((new Date() - new Date(localStorage.getItem('duration'))) / 1000).toFixed(2)

      // sendUserAnalytic({
      //   page: asPath,
      //   duration: leave
      // })
    }

    const handleBeforeUnload = async e => {
      const leave = ((new Date() - new Date(localStorage.getItem('duration'))) / 1000).toFixed(2)

      // sendUserAnalytic({
      //   page: asPath,
      //   duration: leave
      // })
    }

    router.events.on('beforeHistoryChange', handleBeforeHistoryChange)

    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      router.events.off('beforeHistoryChange', handleBeforeHistoryChange)
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [query])

  useEffect(() => {
    const u = navigator.userAgent
    const isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)

    // ios浏览器适配
    if (!isIOS) return

    function setViewHeight() {
      var windowVH = window.innerHeight / 100
      document.documentElement.style.setProperty('--vh', windowVH + 'px')
    }
    setViewHeight()
  }, [])

  // 获取vh高度
  useEffect(() => {
    const setViewportHeight = () => {
      const vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh2', `${vh}px`)
    }

    setViewportHeight()

    window.addEventListener('resize', setViewportHeight)

    return () => {
      window.removeEventListener('resize', setViewportHeight)
    }
  }, [])

  return (
    <Provider store={store}>
      <CacheProvider value={emotionCache}>
        <Head>
          <title>{`${process.env.NEXT_PUBLIC_SITE_TYPE} `}</title>
          <meta name='description' content={`${process.env.NEXT_PUBLIC_SITE_TYPE}`} />
          <meta name='keywords' content={`${process.env.NEXT_PUBLIC_SITE_TYPE}`} />
          <meta name='viewport' content='initial-scale=1, width=device-width' />
        </Head>

        {/* 安易信脚本 */}
        {
          <Script
            src={
              pathname.split('/')[3] === 'checkout' || pathname.split('/')[4] === 'checkout'
                ? process.env.NEXT_PUBLIC_PATTERN
                : ''
            }
            strategy='beforeInteractive'
          />
        }

        <AuthProvider>
          <SettingsProvider {...(setConfig ? { pageSettings: setConfig() } : {})}>
            <SettingsConsumer>
              {({ settings }) => {
                if (
                  asPath.slice(1, 15) === 'user-guide-ios' ||
                  asPath.slice(1, 19) === 'user-guide-android' ||
                  asPath.slice(3, 17) === 'user-guide-ios' ||
                  asPath.slice(3, 21) === 'user-guide-android' ||
                  asPath.slice(1, 16) === 'back-up-android' ||
                  asPath.slice(1, 12) === 'back-up-ios' ||
                  asPath.slice(3, 20) === 'back-up-android' ||
                  asPath.slice(3, 14) === 'back-up-ios' ||
                  asPath.slice(1, 6) === 'guide'
                ) {
                  return (
                    <ThemeComponent settings={settings}>
                      <WindowWrapper>
                        <Guard authGuard={authGuard} guestGuard={guestGuard}>
                          <AclGuard aclAbilities={aclAbilities} guestGuard={guestGuard}>
                            {<ErrorBoundary asPath={asPath}>
                              <Component {...pageProps} />
                            </ErrorBoundary>}
                          </AclGuard>
                        </Guard>
                      </WindowWrapper>
                      <ReactHotToast>
                        <Toaster position={settings.toastPosition} toastOptions={{ className: 'react-hot-toast' }} />
                      </ReactHotToast>
                    </ThemeComponent>
                  )
                } else {
                  return (
                    <ThemeComponent settings={settings}>
                      <WindowWrapper>
                        <Guard authGuard={authGuard} guestGuard={guestGuard}>
                          <AclGuard aclAbilities={aclAbilities} guestGuard={guestGuard}>
                            {getLayout(
                              <ErrorBoundary asPath={asPath}>
                                <Component {...pageProps} />
                              </ErrorBoundary>
                            )}
                          </AclGuard>
                        </Guard>
                      </WindowWrapper>
                      <ReactHotToast>
                        <Toaster position={settings.toastPosition} toastOptions={{ className: 'react-hot-toast' }} />
                      </ReactHotToast>
                    </ThemeComponent>
                  )
                }
              }}
            </SettingsConsumer>
          </SettingsProvider>
        </AuthProvider>
      </CacheProvider>
      <Analytics />
    </Provider>
  )
}

export default appWithTranslation(App)
