const gcloudNavigation = () => {
  return [
    {
      "title": "Dashboard",
      "icon": "/images/navigation/android-cloud-dashboard.svg",
      "path": "/android/dashboard"
    },
    {
      "sectionTitle": " "
    },
    {
      "title": "Text message",
      "icon": "/images/navigation/android-cloud-text-message.svg",
      "path": "/android/text-messages"
    },
    {
      "title": "WhatsApp",
      "icon": "/images/navigation/android-cloud-whatsapp.svg",
      "path": "/android/whatsapp",
      "category": "WHATSAPP_CHAT_SUMMARY"
    },
    {
      "title": "GPS location",
      "icon": "/images/navigation/android-cloud-gps-location.svg",
      "path": "/android/locations",
      "category": "LOCATION_DEVICES"
    },
    {
      "title": "Photo",
      "icon": "/images/navigation/android-cloud-photo.svg",
      "path": "/android/photos"
    },
    {
      "title": "Video",
      "icon": "/images/navigation/android-cloud-video.svg",
      "path": "/android/videos",
      "category": "ALBUM_DETAIL"
    },
    {
      "title": "Email",
      "icon": "/images/navigation/android-cloud-email.svg",
      "path": "/android/email"
    },
    {
      "title": "Contact",
      "icon": "/images/navigation/android-cloud-contact.svg",
      "path": "/android/contacts"
    },
    {
      "title": "Browser history",
      "icon": "/images/navigation/android-cloud-browser-history.svg",
      "path": "/android/browser-history"
    },
    {
      "title": "Browser bookmark",
      "icon": "/images/navigation/android-cloud-browser-bookmark.svg",
      "path": "/android/browser-bookmark"
    },
    {
      "title": "Wi-Fi network",
      "icon": "/images/navigation/android-cloud-wi-fi-network.svg",
      "path": "/android/wifi-networks"
    },
    {
      "title": "Keep",
      "icon": "/images/navigation/android-cloud-keep.svg",
      "path": "/android/keep"
    },
    {
      "title": "My account",
      "icon": "/images/navigation/android-cloud-my-account.svg",
      "path": "/pages/account-settings/account"
    }
  ]
}

export default gcloudNavigation
