// ** React Imports
import { useState, Fragment } from 'react'
import { useTranslation } from 'next-i18next'
import Image from 'next/image'

// ** Next Import
import { useRouter } from 'next/router'

// ** MUI Imports
import Box from '@mui/material/Box'
import Menu from '@mui/material/Menu'
import Badge from '@mui/material/Badge'
import Avatar from '@mui/material/Avatar'
import Divider from '@mui/material/Divider'
import MenuItem from '@mui/material/MenuItem'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'

// ** Icon Imports
import Icon from 'src/@core/components/icon'

// ** Context
import { useAuth } from 'src/hooks/useAuth'
import LanguageDropdown from 'src/@core/layouts/components/shared-components/LanguageDropdown'
import ModeToggler from 'src/@core/layouts/components/shared-components/ModeToggler'

// ** Styled Components
const BadgeContentSpan = styled('span')(({ theme }) => ({
  width: 8,
  height: 8,
  borderRadius: '50%',
  backgroundColor: theme.palette.success.main,
  boxShadow: `0 0 0 2px ${theme.palette.background.paper}`
}))

const UserDropdown = props => {
  const { t } = useTranslation('common')

  // ** Props
  const { settings, saveSettings } = props

  // ** States
  const [anchorEl, setAnchorEl] = useState(null)

  // ** Hooks
  const router = useRouter()
  const { logout } = useAuth()

  const { asPath, query } = useRouter()

  // ** Vars
  const { direction } = settings

  const handleDropdownOpen = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleDropdownClose = url => {
    if (url) {
      router.push(url)
    }
    setAnchorEl(null)
  }

  const styles = {
    py: 2,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    color: 'text.primary',
    textDecoration: 'none',
    fontSize: '0.9rem',
    '& svg': {
      mr: 2,
      fontSize: '1.35rem',
      color: 'text.primary'
    }
  }

  const handleLogout = () => {
    logout()
    handleDropdownClose()
  }

  let userData = JSON.parse(localStorage.getItem('userData')).data

  const [isHovered, setIsHovered] = useState(false)

  return (
    <Fragment>
      <Badge>
        <Avatar
          alt='Aileen'
          onClick={handleDropdownOpen}
          sx={{ width: 30, height: 30 }}
          src={userData.pic.indexOf('	https://res.cloudinary.com') ? '/images/common/avatar.svg' : userData.pic}
        />
      </Badge>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => handleDropdownClose()}
        sx={{ '& .MuiMenu-paper': { width: 'auto', mt: 4 } }}
        anchorOrigin={{ vertical: 'bottom', horizontal: direction === 'ltr' ? 'right' : 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: direction === 'ltr' ? 'right' : 'left' }}
      >
        <Box sx={{ pt: 2, pb: 3, px: 4 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Avatar
              alt='Aileen'
              src={userData.pic.indexOf('	https://res.cloudinary.com') ? '/images/common/avatar.svg' : userData.pic}
              sx={{ width: '2.5rem', height: '2.5rem' }}
            />
            <Box sx={{ display: 'flex', ml: 3, alignItems: 'flex-start', flexDirection: 'column' }}>
              <Typography sx={{ fontWeight: 600 }}>{userData.email}</Typography>
            </Box>
          </Box>
        </Box>

        <MenuItem
          onMouseEnter={() => setIsHovered('settings')}
          onMouseLeave={() => setIsHovered(false)}
          className='hover:bg-gray-300 group'
          sx={{ p: 0, height: '48px', width: '94%', margin: '0 auto', paddingLeft: '0', borderRadius: '8px' }}
          onClick={() => handleDropdownClose('/pages/account-settings/account')}
        >
          <Box
            sx={styles}
            style={{ paddingLeft: '0' }}
            className='group text-black text-opacity-70 font-[600] hover:text-opacity-80 group-hover:text-[#2A99FF]'
          >
            <Image
              src={
                isHovered == 'settings'
                  ? '/images/common/usersetting/myaccount-active.svg'
                  : '/images/common/usersetting/myaccount.svg'
              }
              width='18'
              height='18'
              alt='img'
              style={{ margin: '0 8px' }}
            />
            <span style={{ fontSize: '14px' }}>{t('My account')}</span>
          </Box>
        </MenuItem>

        <MenuItem
          onMouseEnter={() => setIsHovered('Referral Program')}
          onMouseLeave={() => setIsHovered(false)}
          className='hover:bg-gray-300 group'
          sx={{ p: 0, height: '48px', width: '94%', margin: '0 auto', paddingLeft: '0', borderRadius: '8px' }}
          onClick={() => handleDropdownClose('/referral-program')}
        >
          <Box
            style={{ paddingLeft: '0' }}
            sx={styles}
            className='group text-black text-opacity-70 font-[600] hover:text-opacity-80 group-hover:text-[#2A99FF]'
          >
            <Image
              src={
                isHovered == 'Referral Program'
                  ? '/images/common/usersetting/changepassword-active.svg'
                  : '/images/common/usersetting/changepassword.svg'
              }
              width='18'
              height='18'
              alt='img'
              style={{ margin: '0 8px' }}
            />

            {t('Referral Program')}
          </Box>
        </MenuItem>

        {asPath.split('/')[1] && asPath.split('/')[1] !== 'android' && asPath.split('/')[2] !== 'android' && (
          <>
            <MenuItem
              onMouseEnter={() => setIsHovered('use-guide')}
              onMouseLeave={() => setIsHovered(false)}
              className='hover:bg-gray-300 group'
              sx={{ p: 0, height: '48px', width: '94%', margin: '0 auto', paddingLeft: '0', borderRadius: '8px' }}
              onClick={() => {
                const allLang = ['jp', 'de', 'kr', 'fr', 'es', 'it-ch', 'pt']
                const currentLang = location.pathname.split('/')[1]
                if (allLang.includes(currentLang)) {
                  window.open(`/${currentLang}/guide`)
                } else {
                  window.open(`/guide`)
                }
              }}
            >
              <Box
                sx={styles}
                className='group text-black text-opacity-70 font-[600] hover:text-opacity-80 group-hover:text-[#2A99FF]'
                style={{ margin: 0 }}
              >
                <Image
                  src={
                    isHovered == 'use-guide'
                      ? '/images/common/usersetting/useguide-active.svg'
                      : '/images/common/usersetting/useguide.svg'
                  }
                  width='18'
                  height='18'
                  alt='img'
                  style={{ margin: '0 8px' }}
                />
                {t('User guide')}
              </Box>
            </MenuItem>
          </>
        )}

        {(asPath.split('/')[1] === 'android' || asPath.split('/')[2] === 'android') && (
          <>
            <MenuItem
              onMouseEnter={() => setIsHovered('use-guide')}
              onMouseLeave={() => setIsHovered(false)}
              className='hover:bg-gray-300 group'
              sx={{ p: 0, height: '48px', width: '94%', margin: '0 auto', paddingLeft: '0', borderRadius: '8px' }}
              onClick={() => {
                const allLang = ['jp', 'de', 'kr', 'fr', 'es', 'it-ch', 'pt']
                const currentLang = location.pathname.split('/')[1]
                if (allLang.includes(currentLang)) {
                  window.open(`/${currentLang}/guide`)
                } else {
                  window.open(`/guide`)
                }
              }}
            >
              <Box
                sx={styles}
                className='group text-black text-opacity-70 font-[600] hover:text-opacity-80 group-hover:text-[#2A99FF]'
                style={{ paddingLeft: '0' }}
              >
                <Image
                  src={
                    isHovered == 'use-guide'
                      ? '/images/common/usersetting/useguide-active.svg'
                      : '/images/common/usersetting/useguide.svg'
                  }
                  width='18'
                  height='18'
                  alt='img'
                  style={{ margin: '0 8px' }}
                />
                {t('User guide')}
              </Box>
            </MenuItem>
          </>
        )}

        <MenuItem
          onMouseEnter={() => setIsHovered('Email Support')}
          onMouseLeave={() => setIsHovered(false)}
          className='hover:bg-gray-300 group'
          sx={{ p: 0, height: '48px', width: '94%', margin: '0 auto', paddingLeft: '0', borderRadius: '8px' }}
          onClick={() =>
            (location.href = `${process.env.NEXT_PUBLIC_CONTACT_URL}?type=${localStorage.getItem('currentMode')}`)
          }
        >
          <Box
            sx={styles}
            style={{ paddingLeft: '0' }}
            className='group text-black text-opacity-70 font-[600] hover:text-opacity-80 group-hover:text-[#2A99FF]'
          >
            <Image
              src={
                isHovered == 'Email Support'
                  ? '/images/common/usersetting/email-active.svg'
                  : '/images/common/usersetting/email.svg'
              }
              width='18'
              height='18'
              alt='img'
              style={{ margin: '0 8px' }}
            />
            {t('Email Support')}
          </Box>
        </MenuItem>

        <MenuItem
          onMouseEnter={() => setIsHovered('Suggestion Box')}
          onMouseLeave={() => setIsHovered(false)}
          className='hover:bg-gray-300 group'
          sx={{ p: 0, height: '48px', width: '94%', margin: '0 auto', paddingLeft: '0', borderRadius: '8px' }}
          onClick={() => handleDropdownClose('/suggestion-box')}
        >
          <Box
            sx={styles}
            style={{ paddingLeft: '0' }}
            className='group text-black text-opacity-70 font-[600] hover:text-opacity-80 group-hover:text-[#2A99FF]'
          >
            <Image
              src={
                isHovered == 'Suggestion Box'
                  ? '/images/common/usersetting/suggestion-active.svg'
                  : '/images/common/usersetting/suggestion.svg'
              }
              width='18'
              height='18'
              alt='img'
              style={{ margin: '0 8px' }}
            />
            {t('Suggestion Box')}
          </Box>
        </MenuItem>

        <MenuItem
          onMouseEnter={() => setIsHovered('Settings')}
          onMouseLeave={() => setIsHovered(false)}
          className='hover:bg-gray-300 group'
          sx={{ p: 0, height: '48px', width: '94%', margin: '0 auto', paddingLeft: '0', borderRadius: '8px' }}
          onClick={() => handleDropdownClose('/settings')}
        >
          <Box
            style={{ paddingLeft: '0' }}
            sx={styles}
            className='group text-black text-opacity-70 font-[600] hover:text-opacity-80 group-hover:text-[#2A99FF]'
          >
            <Image
              src={
                isHovered == 'Settings'
                  ? '/images/common/usersetting/setting-active.svg'
                  : '/images/common/usersetting/setting.svg'
              }
              width='18'
              height='18'
              alt='img'
              style={{ margin: '0 8px' }}
            />
            {t('Settings')}
          </Box>
        </MenuItem>

        <LanguageDropdown settings={settings} saveSettings={saveSettings} />

        {/* <ModeToggler settings={settings} saveSettings={saveSettings} /> */}

        <MenuItem
          onMouseEnter={() => setIsHovered('Logout')}
          onMouseLeave={() => setIsHovered(false)}
          onClick={handleLogout}
          className='hover:bg-gray-300 group'
          sx={{ p: 0, height: '48px', width: '94%', margin: '0 auto', paddingLeft: '0', borderRadius: '8px' }}
        >
          <Box
            sx={styles}
            style={{ paddingLeft: '0' }}
            className='group text-black text-opacity-70 font-[600] hover:text-opacity-80 group-hover:text-[#2A99FF]'
          >
            <Image
              src={
                isHovered == 'Logout'
                  ? '/images/common/usersetting/logout-active.svg'
                  : '/images/common/usersetting/logout.svg'
              }
              width='18'
              height='18'
              alt='img'
              style={{ margin: '0 8px' }}
            />
            <span style={{ fontSize: '14px' }}> {t('Logout')}</span>
          </Box>
        </MenuItem>
      </Menu>
    </Fragment>
  )
}

export default UserDropdown
